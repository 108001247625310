import { elementColors, signs } from "../../data/zodiacConstants";
import { useBodyViewSettings } from "../../contexts/bodyViewContext";

export const getHouseForDegree = (degree, cusps) => {
    for (let i = 0; i < cusps.length; i++) {
        const nextIndex = (i + 1) % cusps.length;
        const start = cusps[i];
        const end = cusps[nextIndex];
        
        if (end > start) {
            if (degree >= start && degree < end) {
                return i + 1;
            }
        } else {
            // Handle case where house spans 0°
            if (degree >= start || degree < end) {
                return i + 1;
            }
        }
    }
    return 1; // Default to first house if not found
};

const HousePositionCard = ({ bodies = [], cusps }) => {
    const { isChartVisible } = useBodyViewSettings();

    const zodiacSigns = [
        "Aries", "Taurus", "Gemini", "Cancer",
        "Leo", "Virgo", "Libra", "Scorpio",
        "Sagittarius", "Capricorn", "Aquarius", "Pisces"
    ];

    // Filter visible bodies first
    const visibleBodies = bodies.filter(body => isChartVisible(body.name));

    // Then calculate houses for visible bodies only
    const bodiesInHouses = {};
    visibleBodies.forEach(body => {
        const totalDegree =  body.degree;
        const house = getHouseForDegree(totalDegree, cusps);
        if (!bodiesInHouses[house]) {
            bodiesInHouses[house] = [];
        }
        bodiesInHouses[house].push(body);
    });

    return (
        <div style={{
            marginBottom: 24,
            // padding: 12,
            borderRadius: 4,
            backgroundColor: 'rgba(255,255,255,0)',
        }}>
            <div style={{ 
                fontWeight: 'bold',
                marginBottom: 8,
                borderBottom: '1px solid rgba(255,255,255,0.2)',
                paddingBottom: 4,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                {/* <span>Houses</span> */}
            </div>
            <div style={{ fontSize: 14 }}>
                {cusps.map((degree, i) => {
                    const houseNumber = i + 1;
                    const signIndex = Math.floor(degree / 30);
                    const signDegree = degree % 30;
                    const signData = signs.filter(sign => sign.name === zodiacSigns[signIndex])[0];

                    return (
                        <div key={i} style={{ 
                            marginBottom: 4,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8
                        }}>
                            <span style={{ 
                                minWidth: 64, width: 64, 
                                color: 'white', 
                                textAlign: 'left',
                                fontSize: 10, 
                            }}>
                               House {houseNumber}
                            </span>
                            <span style={{ 
                                // flex: 1,
                                width: 96,
                                fontSize: 10,
                            }}>
                                <span className="symbolText" style={{
                                    lineHeight: '10px', 
                                    color: elementColors[signData.element]
                                }}>
                                    {signData.symbol}
                                </span> {zodiacSigns[signIndex]} {signDegree.toFixed(1)}°
                            </span>
                            
                    
                            <span style={{ 
                                flex: 1,
                                fontSize: 12,
                                lineHeight: '12px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 8
                            }}>
                                {bodiesInHouses[houseNumber]?.map((body, index) => (
                                    <span key={index} className="symbolText" style={body.circle ?{ 
                                        
                                        color: body.color,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 4, border: '1px solid '+ body.color, borderRadius: 10,width: 10, height: 10,
                                    } : {
                                        color: body.color,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 4
                                    }}>
                                        {body.symbol}
                                    </span>
                                )) || ''}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HousePositionCard;
