// Utility functions for managing WebGL resources
export const disposeWebGLResources = (globe) => {
  if (!globe || !globe.current) return;

  try {
    // Get WebGL context
    const canvas = globe.current._canvas;
    if (!canvas) return;
    
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (!gl) return;

    // Delete textures
    const textures = gl.getParameter(gl.TEXTURE_BINDING_2D);
    if (textures) {
      if (Array.isArray(textures)) {
        textures.forEach(texture => gl.deleteTexture(texture));
      } else {
        gl.deleteTexture(textures);
      }
    }

    // Delete buffers
    const buffers = gl.getParameter(gl.ARRAY_BUFFER_BINDING);
    if (buffers) {
      if (Array.isArray(buffers)) {
        buffers.forEach(buffer => gl.deleteBuffer(buffer));
      } else {
        gl.deleteBuffer(buffers);
      }
    }

    // Delete shaders and programs
    const program = gl.getParameter(gl.CURRENT_PROGRAM);
    if (program) {
      const shaders = gl.getAttachedShaders(program);
      if (shaders) {
        shaders.forEach(shader => {
          gl.detachShader(program, shader);
          gl.deleteShader(shader);
        });
      }
      gl.deleteProgram(program);
    }

    // Clear any errors that might have occurred
    gl.getError();
  } catch (e) {
    console.error('Error disposing WebGL resources:', e);
  }
};

export const clearMarkers = (markerPool) => {
  if (!markerPool || !markerPool.current) return;
  
  try {
    markerPool.current.forEach(marker => {
      if (marker && marker.remove && typeof marker.remove === 'function') {
        marker.remove();
      }
    });
    markerPool.current.clear();
  } catch (e) {
    console.error('Error clearing markers:', e);
  }
};
