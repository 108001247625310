import React, { useRef, useEffect, useState } from "react"

import { AspectItem } from "./AspectItem";
import { getUniqueRelocSynastryAspects, isAspectInSearch } from "./aspects.helpers";
import { ToggleSwitch } from "../modalsmenus/LineViewSettings";
import { useBodyViewSettings } from "../../contexts/bodyViewContext";

const colWidth = 200;




const AspectToggle = ({ aspectKey, aspectInfo, enabled, onToggle }) => (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      padding: '4px 8px',
      cursor: 'pointer',
      opacity: enabled ? 1 : 0.5,
      transition: 'opacity 0.2s ease'
    }} onClick={onToggle}>
      <div style={{
        width: 20,
        height: 20,
        borderRadius: 4,
        border: '1px solid rgba(255,255,255,0.3)',
        backgroundColor: enabled ? 'white' : 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background-color 0.2s ease'
      }}>
        {enabled && <span style={{ color: 'black' }}>✓</span>}
      </div>
      {/* <span className="symbolText" style={{ fontSize: 16 }}>{aspectInfo.symbol}</span> */}
      <span style={{ textTransform: 'capitalize' }}>{aspectKey}</span>
    </div>
  );

const AspectList = React.memo(({ aspects, title, aspectSearch, setSelectedAspect, selectedAspect, isMobile }) => {
    const [sortByOrb, setSortByOrb] = useState(false);

    const scrollContainerRef = useRef(null);
    const { isAspectVisible } = useBodyViewSettings()

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0; // Reset scroll to top when selectedAspect changes
        }
    }, [selectedAspect]);

    if (!aspects || aspects.length === 0) return null;
    
    const selectedAspectInThisList = aspects.find(aspect => 
        aspect.point1Label === selectedAspect?.point1Label && 
        aspect.point2Label === selectedAspect?.point2Label &&
        aspect.point1Source === selectedAspect?.point1Source &&
        aspect.point2Source === selectedAspect?.point2Source
    );

    const searchResults = aspects.filter(aspect => 
        isAspectVisible(aspect.point1Label) && isAspectVisible(aspect.point2Label) &&
        isAspectInSearch(aspect, aspectSearch) && 
        aspect !== selectedAspectInThisList 
        // && aspect.orb < 5
    );
    
    const remainingAspects = aspects.filter(aspect => {
        // console.log(aspect.point1Label, isAspectVisible(aspect.point1Label))
            return isAspectVisible(aspect.point1Label) && isAspectVisible(aspect.point2Label) &&
            !isAspectInSearch(aspect, aspectSearch) && 
            aspect !== selectedAspectInThisList 
    }
        // && aspect.orb < 5
    );
    // console.log(title,remainingAspects)

    const applySort = aspectArray => {
        if (sortByOrb) {
            return aspectArray.sort((a, b) => a.orb < b.orb ? -1 : 1);
        } else return aspectArray
    }
    

    return (
        <div style={{height: 'inherit', maxHeight: 'inherit', width: isMobile ? 'inherit' : 'auto'}}>
            <div style={{
                color: 'white',
                marginBottom: 8,
                fontWeight: 'bold',
                fontSize: '0.9em',
                opacity: 0.8,
                marginTop: 16,
                display: 'flex',
                flexDirection: 'row', 
                alignItems: 'center'
            }}>
                <div>{title}</div>
                <div style={{flex: 1}} />
                <div style={{fontSize: 10, color: 'rgba(255,255,255,0.5)', marginRight: 8}} >SORT BY ORB</div>
                <ToggleSwitch isOn={() => sortByOrb} onClick={() => setSortByOrb(!sortByOrb)} />
            </div>
            <div 
                style={{
                    maxHeight: 'calc(100% - 0px)',
                    overflowY: 'auto'
                }}
                ref={scrollContainerRef} // Attach ref to scrollable container
            >
                {selectedAspectInThisList && (
                    <div style={{ marginBottom: 12 }}>
                        <AspectItem
                            aspect={selectedAspectInThisList}
                            setSelectedAspect={setSelectedAspect}
                            isSelected={true}
                            isMobile={isMobile}
                        />
                    </div>
                )}
                
                {searchResults.length > 0 && (
                    <div style={{ marginBottom: 12 }}>
                        <div style={{ fontSize: 12, color: 'rgba(255,255,255,0.6)', marginBottom: 4 }}>
                            Related Aspects
                        </div>
                        {applySort(searchResults).map((aspect, index) => (
                            <AspectItem 
                                key={`search-${aspect.point1Label}-${aspect.point2Label}-${index}`}
                                aspect={aspect}
                                setSelectedAspect={setSelectedAspect}
                                isSearchResult={true}
                                isMobile={isMobile}
                            />
                        ))}
                    </div>
                )}

                {remainingAspects.length > 0 && (
                    <div>
                        {selectedAspect ? <div style={{ fontSize: 12, color: 'rgba(255,255,255,0.6)', marginBottom: 4 }}>
                            Other Aspects
                        </div> : null}
                        {applySort(remainingAspects).map((aspect, index) => (
                            <AspectItem 
                                key={`other-${aspect.point1Label}-${aspect.point2Label}-${aspect.label}-${index}`}
                                aspect={aspect}
                                setSelectedAspect={setSelectedAspect}
                                isMobile={isMobile}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
})





const AspectLists = React.memo(({aspects, aspectSearch, processedAspects, getAspectsNo, selectedAspect, setSelectedAspect, synastryAspects, relocSynastryAspects, showList, isMobile}) => {
    const colMar = 16;
    //const  = props;


    const [uniqueSynReloc, setUniqueSynReloc] = useState([]);
    const [reloc3, setReloc3] = useState([]);
    const [reloc4, setReloc4] = useState([]);

    useEffect(() => {
        setUniqueSynReloc([...getUniqueRelocSynastryAspects(relocSynastryAspects, synastryAspects)]);
    }, [synastryAspects, relocSynastryAspects])

    useEffect(() => {
        setReloc3(getUniqueRelocSynastryAspects(processedAspects[3], processedAspects[1]));
        setReloc4(getUniqueRelocSynastryAspects(processedAspects[4], processedAspects[2]));
    }, [processedAspects])
    // console.log(reloc3,reloc4)

    if (showList === 'A1' || showList === 'C1') {
        return                     <AspectList 
        aspects={processedAspects[1]} 
        aspectSearch={aspectSearch}
        setSelectedAspect={setSelectedAspect}
        selectedAspect={selectedAspect}
        isMobile={isMobile}
        title="Chart 1"
        />
    } else if (showList === 'SYN') {
        return                     <AspectList 
        aspects={synastryAspects} 
        aspectSearch={aspectSearch}
        setSelectedAspect={setSelectedAspect}
        selectedAspect={selectedAspect}
        isMobile={isMobile}
        title="Synastry 1,2"
        />
    }else if (showList === 'A2' || showList === 'C2') {
        return                                        <AspectList 
        aspects={processedAspects[2]} 
        aspectSearch={aspectSearch}
        setSelectedAspect={setSelectedAspect}
        selectedAspect={selectedAspect}
        isMobile={isMobile}
        title="Chart 2"
    />
    }else if (showList === 'R1') {
        return                                        <AspectList 
        aspects={reloc3} 
        aspectSearch={aspectSearch}
        setSelectedAspect={setSelectedAspect}
        selectedAspect={selectedAspect}
        isMobile={isMobile}
        title="Relocated 1 - Unique"
    />
    }else if (showList === 'R2') {
        return                                        <AspectList 
        aspects={reloc4} 
        aspectSearch={aspectSearch}
        setSelectedAspect={setSelectedAspect}
        selectedAspect={selectedAspect}
        isMobile={isMobile}
        title="Relocated 2 - Unique"
    />
    }else if (showList === 'RSYN') {
        return                <AspectList 
        aspects={uniqueSynReloc} 
        aspectSearch={aspectSearch}
        setSelectedAspect={setSelectedAspect}
        selectedAspect={selectedAspect}
        isMobile={isMobile}
        title="Relocated Syn - Unique"
    />
    }    if (showList === 'DC') {
        return                     <AspectList 
        aspects={processedAspects[7]} 
        aspectSearch={aspectSearch}
        setSelectedAspect={setSelectedAspect}
        selectedAspect={selectedAspect}
        isMobile={isMobile}
        title="Davison Composite"
        />
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'calc(80dvh - 96px)' }}>
            {/* {selectedAspect && (
                <button 
                    onClick={() => setSelectedAspect(null)}
                    style={{
                        marginBottom: 16,
                        padding: '4px 12px',
                        backgroundColor: 'rgba(255,68,68,0.2)',
                        border: '1px solid #ff4444',
                        borderRadius: 4,
                        color: 'white',
                        cursor: 'pointer'
                    }}
                >
                    Clear Selection
                </button>
            )} */}
            <div style={{
                //width: 45+180*4, 
                backgroundColor: 'rgba(0,0,0,.66)', 
                //border: '1px solid white', 
                height: 'inherit',
                display: 'flex', 
                flexDirection: 'row',
                //padding: 16,
                borderRadius: 8
            }}>
                <div style={{width: colWidth, height: 'inherit',marginRight: colMar}}>
                    <AspectList 
                        aspects={processedAspects[1]} 
                        aspectSearch={aspectSearch}
                        setSelectedAspect={setSelectedAspect}
                        selectedAspect={selectedAspect}
                        title="Chart 1 Aspects"
                    />
                </div>
                
                
                <div style={{width: colWidth, height: 'inherit',marginRight: colMar}}>
                    <AspectList 
                        aspects={processedAspects[2]} 
                        aspectSearch={aspectSearch}
                        setSelectedAspect={setSelectedAspect}
                        selectedAspect={selectedAspect}
                        title="Chart 2 Aspects"
                    />
                </div>
                <div style={{width: colWidth, height: 'inherit',marginRight: colMar}}>
                    <AspectList 
                        aspects={synastryAspects} 
                        aspectSearch={aspectSearch}
                        setSelectedAspect={setSelectedAspect}
                        selectedAspect={selectedAspect}
                        title="Synastry Aspects"
                    />
                </div>
                
                <div style={{width: colWidth, height: 'inherit',marginRight: 0}}>
                    <AspectList 
                        aspects={uniqueSynReloc} 
                        aspectSearch={aspectSearch}
                        setSelectedAspect={setSelectedAspect}
                        selectedAspect={selectedAspect}
                        title="RELOCATION-UNIQUE"
                    />
                </div>
            </div>
        </div>
    );
});
export default AspectLists;