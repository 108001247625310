import React, { createContext, useContext, useState, useEffect,useCallback, useRef } from 'react';
import { bodyReference } from '../scripts/chart';
import { useUser } from './userContext';

const BodyViewContext = createContext(null);

const getInitialState = () => {
  const initialState = {};
  Object.keys(bodyReference).forEach((body) => {
    initialState[body] = {
      general: true, // Default general visibility
      line: bodyReference[body].part || bodyReference[body].asteroid || bodyReference[body].axis ? false : true,
      chart: true,
      aspect: bodyReference[body].part || bodyReference[body].axisLower || bodyReference[body].aspectConjunctOnly ? false : true,
      transit: bodyReference[body].part || bodyReference[body].axisLower ? false : true
    };
  });
  return initialState;
};

export const BodyViewProvider = ({ children }) => {
  const { user, userData, saveUserPreferences } = useUser();
  const [focusViewState, setFocusViewState] = useState(getInitialState);

  // Load user preferences when they become available
  useEffect(() => {
    if (userData?.preferences?.focusViewState) {
      setFocusViewState(userData.preferences.focusViewState);
    }
  }, [userData?.preferences?.focusViewState]);

  const saveTimeoutRef = useRef(null);
  const isInitialLoadRef = useRef(true);

  // Debounced save to backend
  const debouncedSave = useCallback((state) => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      
      saveUserPreferences({ focusViewState: state }).catch(error => {
        console.error('Error saving focus view state:', error);
      });
    }, 1000); // 1 second debounce
  }, [saveUserPreferences]);

  // Save changes to backend
  useEffect(() => {
    // Skip the initial load to prevent unnecessary saves
    if (isInitialLoadRef.current) {
      isInitialLoadRef.current = false;
      return;
    }
    
    if (user) debouncedSave(focusViewState);

    // Cleanup timeout on unmount
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, [focusViewState]);

  // Getters
  const isBodyVisible = (name) => focusViewState[name]?.general ?? false;
  const isLineVisible = (name) => focusViewState[name]?.line ?? false;
  const isChartVisible = (name) => focusViewState[name]?.chart ?? false;
  const isAspectVisible = (name) => focusViewState[name]?.aspect ?? false;
  const isTransitVisible = (name) => focusViewState[name]?.transit ?? false;

  // Setters (only update local state)
  const setFocusBodyVisibility = (bodyName, type, isVisible) => {
    setFocusViewState((prevState) => ({
      ...prevState,
      [bodyName]: {
        ...prevState[bodyName],
        [type]: isVisible
      }
    }));
  };

  const toggleFocusBodyVisibility = (bodyName, type) => {
    setFocusViewState((prevState) => ({
      ...prevState,
      [bodyName]: {
        ...prevState[bodyName],
        [type]: !prevState[bodyName]?.[type]
      }
    }));
  };

  // Legacy support for old API
  const setBodyVisibility = (bodyName, isVisible) => 
    setFocusBodyVisibility(bodyName, 'general', isVisible);

  const toggleBodyVisibility = (bodyName) => 
    toggleFocusBodyVisibility(bodyName, 'general');

  const value = {
    // New consolidated state and methods
    focusViewState,
    setFocusBodyVisibility,
    toggleFocusBodyVisibility,
    isBodyVisible,
    isLineVisible,
    isChartVisible,
    isAspectVisible,
    isTransitVisible,

    // Legacy support (maps to general visibility)
    viewState: Object.fromEntries(
      Object.entries(focusViewState).map(([key, value]) => [key, value.general])
    ),
    lineViewState: Object.fromEntries(
      Object.entries(focusViewState).map(([key, value]) => [key, value.line])
    ),
    chartViewState: Object.fromEntries(
      Object.entries(focusViewState).map(([key, value]) => [key, value.chart])
    ),
    aspectViewState: Object.fromEntries(
      Object.entries(focusViewState).map(([key, value]) => [key, value.aspect])
    ),
    transitViewState: Object.fromEntries(
      Object.entries(focusViewState).map(([key, value]) => [key, value.transit])
    ),
    setBodyVisibility,
    toggleBodyVisibility
  };

  return <BodyViewContext.Provider value={value}>{children}</BodyViewContext.Provider>;
};

export const useBodyViewSettings = () => {
  const context = useContext(BodyViewContext);
  if (!context) throw new Error('useBodyViewSettings must be used within BodyViewProvider');
  return context;
};

export default BodyViewProvider;