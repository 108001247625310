import React, { createContext, useContext, useEffect, useState } from 'react';
import { addNameKeyToAspects } from '../scripts/aspects';

const BodiesContext = createContext(null);

export const BodiesProvider = ({ children }) => {
  const [bodies, setBodies] = useState([]);
  const [bodies2, setBodies2] = useState([]);
  const [bodiesIndex, setBodiesIndex] = useState([]);
  const [angleBodies, setAngleBodies] = useState({});
  const [cusps, setCusps] = useState({});
  const [aspects, setAspects] = useState({});
  const [processedAspects, setProcessedAspects] = useState({});
  const [parts, setParts] = useState({});
  const [asteroids, setAsteroids] = useState({});

  const getBodiesIndexNo = (n) => bodiesIndex[n] || [];
  const setBodiesIndexNo = (b, n) => setBodiesIndex(prevState => ({...prevState, [n]: b}));

  const setBodiesNo = (b, n) => {
    if (n === 1) setBodies(b);
    if (n === 2) setBodies2(b);
    else setBodiesIndexNo(b, n);
  };

  const getBodiesNo = (n) => {
    if (n === 1) return bodies;
    if (n === 2) return bodies2;
    else return getBodiesIndexNo(n);
  };

  const setAngleBodiesNo = (AB, n) => setAngleBodies(prevState => ({...prevState, [n]: AB}));
  const getAngleBodiesNo = n => angleBodies[n] || [];

  const setCuspsNo = (C, n) => setCusps(prevState => ({...prevState, [n]: C}));
  const getCuspsNo = n => cusps[n] || [];

  const setAspectsNo = (C, n) => setAspects(prevState => ({...prevState, [n]: C}));
  const getAspectsNo = n => aspects[n] || [];

  const setPartsNo = (C, n) => setParts(prevState => ({...prevState, [n]: C}));
  const getPartsNo = n => parts[n] || [];
  
  const setAsteroidsNo = (C, n) => setAsteroids(prevState => ({...prevState, [n]: C}));
  const getAsteroidsNo = n => asteroids[n] || []

  useEffect(() => {
    const newAspects = {};
    Object.keys(aspects).forEach((key) => {
        newAspects[key] = addNameKeyToAspects(getAspectsNo(key), {
            point1Source: parseInt(key), 
            point2Source: parseInt(key)
        });
    });
    setProcessedAspects(newAspects);
}, [aspects])

  const getPAspectsNo = n => processedAspects[n]?processedAspects[n] : aspects[n]?aspects[n] : []

  const resetBodiesData = (n) => {
    setBodiesNo([], n)
    setAngleBodiesNo([],n)
    setCuspsNo([],n)
    setAspectsNo([], n)
    setPartsNo([], n)
    setAsteroidsNo([], n)
  }

  const getCombinedBodiesNo = (n) => {
    return [
      ...getBodiesNo(n),
      ...getAngleBodiesNo(n),
      ...getPartsNo(n),
      ...getAsteroidsNo(n)
    ];
  };

  const value = {
    bodies,
    bodies2,
    getBodiesIndexNo,
    setBodiesIndexNo,
    aspects,
    processedAspects,
    getPAspectsNo,
    cusps,
    parts,
    angleBodies,
    setBodiesNo,
    getBodiesNo,
    setAngleBodiesNo,
    getAngleBodiesNo,
    setCuspsNo,
    getCuspsNo,
    setAspectsNo,
    getAspectsNo,
    setPartsNo,
    getPartsNo,
    setAsteroidsNo,
    getAsteroidsNo,
    getCombinedBodiesNo,
    resetBodiesData
  };

  return <BodiesContext.Provider value={value}>{children}</BodiesContext.Provider>;
};

export const useBodiesData = () => {
  const context = useContext(BodiesContext);
  if (!context) throw new Error('useBodiesData must be used within BodiesProvider');
  return context;
};

export default BodiesProvider;