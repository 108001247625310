import React, { useEffect } from 'react';
import { useSummary } from '../../contexts/summaryContext';
import { LineItem } from '../powerlines/LinesList';
import { useViewData } from '../../contexts/viewContext';
import { AspectItem } from '../aspects/AspectItem';
import HousePositionCard from '../planetarytables/HousePositionCard';
import { elementColors } from '../../data/zodiacConstants';


const SummaryModal = () => {
  const { isOpen, selectedSummaryLine, closeSummaryModal, selectedInfo, selectedInterpretations } = useSummary();
  
  const { getIsMobile } = useViewData();

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        closeSummaryModal();
      }
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [closeSummaryModal]);

  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1005,
      pointerEvents: 'auto',
    }}>
      <div style={{
        backgroundColor: 'rgba(0,0,0,0.8)',
        backdropFilter: 'blur(2px)',
        border: '1px solid rgba(255,255,255,0.3)',
        borderRadius: getIsMobile() ? 16 : 8,
        padding: 24,
        position: 'relative',
        width: isMobile ? '90vw' : '600px',
        maxHeight: isMobile ? '80vh' : '70vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}>
        <button
          onClick={closeSummaryModal}
          style={{
            position: 'absolute',
            top: 16,
            right: 16,
            backgroundColor: 'rgba(255,244,244,0.2)',
            border: '1px solid #ff4444',
            borderRadius: 4,
            color: 'white',
            padding: '4px 8px',
            cursor: 'pointer',
            fontSize: 12,
            zIndex: 1,
          }}
        >
          ✕
        </button>

        <div style={{ 
          marginTop: 16,
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}>
          {selectedSummaryLine && (
            <>
              <LineItem
                line={selectedSummaryLine}
                hasInterpretation={false}
                setSelectedLine={() => {}}
              />
              <div style={{
                fontSize: 12,
                color: 'rgba(255,255,255,0.7)',
                lineHeight: '1.5',
                marginTop: 16,
                padding: '16px',
                backgroundColor: 'rgba(255,255,255,0.05)',
                borderRadius: 8,
              }}>
                <div style={{ marginBottom: 8, color: 'white', fontWeight: 500 }}>Details</div>
                <div>Distance: {Math.floor(selectedSummaryLine.distance_km * 10) / 10} km ({Math.floor(selectedSummaryLine.distance_mi * 10) / 10} mi)</div>
                {/* <div>Type: {selectedSummaryLine.type}</div> */}
                {selectedSummaryLine.angle && <div>Angle: {selectedSummaryLine.angle}</div>}
              </div>
            </>
          )}
        {selectedInfo && selectedInfo.body && 
            <div style={{color: 'white', }} >
                this is in development and doesn't work great for non-primary lines! <br />
                <span className='symbolText' style={ {fontSize: 20, color: selectedInfo.body.color}}>{selectedInfo.body.symbol}</span>
                <span className='symbolText' style={{fontWeight: 300}}> at {selectedInfo?.sign?.degree}° {selectedInfo?.sign?.minutes}" </span>
                <span className='symbolText' style={{fontSize: 12, color: elementColors[selectedInfo?.sign?.sign?.element]}}>{selectedInfo?.sign?.sign?.symbol} </span>
                <span className='symbolText' style={{fontWeight: 300}}>in House {selectedInfo.houses.natal} ⩥ relocated to House {selectedInfo.houses.relocated}</span></div>
            // <HousePositionCard cusps={selectedInfo.cusps} bodies={selectedInfo.bodies} />
        }
        {selectedInfo && selectedInfo.relatedAspects && selectedInfo?.relatedAspects.length > 0 && (
            <div style={{ marginBottom: 12 }}>
                {selectedInfo.relatedAspects.map((aspect, index) => (
                    <AspectItem
                        key={`search-${aspect.point1Label}-${aspect.point2Label}-${index}`}
                        aspect={aspect}
                        setSelectedAspect={() => {}}
                        isSearchResult={false}
                        isMobile={getIsMobile()}
                    />
                ))}
            </div>
        )}
        {selectedInterpretations && selectedInterpretations.length>0 && selectedInterpretations.map((interpretation, i) => {
                return <div key={`${selectedSummaryLine.interpretationKey}-${i}`}>
                  <div style={{fontSize: 11, color: 'white', lineHeight: '16px', whiteSpace: interpretation.source === 'trine earth' ? 'pre-wrap' : ''}}>{interpretation.interpretation}</div>
                  <div style={{width: '100%', textAlign: 'right', color: 'rgba(255,255,255,.5)', marginTop: 8, fontSize: 9, marginBottom: 8}}>
                        excerpt from <span style={{textDecoration: 'underline', cursor: 'pointer', color: 'magenta'}} onClick={() => {
                            window.open(interpretation.sourceUrl, '_blank')
                        }}>{interpretation.source}</span> on {new Date(interpretation.createdAt ? interpretation.createdAt._seconds ? interpretation.createdAt._seconds*1000 : interpretation.createdAt : interpretation.createdAt).toDateString()}</div>
                </div>
        })}
        </div>
      </div>
    </div>
  );
};

export default SummaryModal;