import { bodyReference } from "../scripts/chart";
import { getTimezoneFromCoordinates } from "../scripts/helpers";
import { CacheManager } from "./cache";
import { getAuth } from 'firebase/auth';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://localhost:3033';
const cacheManager = new CacheManager();

class HttpError extends Error {
  constructor(message, status) {
    super(message);
    this.name = 'HttpError';
    this.status = status;
  }
}

const getCacheKey = (endpoint, params) => 
  `${endpoint}-${JSON.stringify(params)}`;

const withCache = async (cacheKey, fetchFn) => {
  const cachedResult = await cacheManager.get(cacheKey);
  if (cachedResult) {
    return cachedResult;
  }

  const result = await fetchFn();
  await cacheManager.set(cacheKey, result);
  return result;
};

const getAuthHeaders = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const headers = {
    'Content-Type': 'application/json'
  };

  if (user) {
    const token = await user.getIdToken();
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
};

const defaultHeaders = {
  'Content-Type': 'application/json'
};

export const getUser = async (userId) => {
  try {
    const headers = await getAuthHeaders();
    const response = await fetch(`${BACKEND_URL}/users/${userId}`, {
      method: 'GET',
      headers
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new HttpError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const headers = await getAuthHeaders();
    const response = await fetch(`${BACKEND_URL}/users/${userId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(userData)
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new HttpError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }

    return response.json();
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const updateUserPreferences = async (userId, preferences) => {
  try {
    const headers = await getAuthHeaders();
    const response = await fetch(`${BACKEND_URL}/users/${userId}/preferences`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(preferences)
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new HttpError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }

    return response.json();
  } catch (error) {
    console.error('Error updating user preferences:', error);
    throw error;
  }
};

export const getPowerlines = async (patterns) => {
  const cacheKey = getCacheKey('powerlines', { patterns });
  
  return withCache(cacheKey, async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/powerlines`, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({ patterns })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new HttpError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
      }
      
      return response.json();
    } catch (error) {
      console.error('Error querying powerlines:', error);
      throw error;
    }
  });
};

export const getSynastryPowerlines = async (patterns) => {
  const cacheKey = getCacheKey('powerlines', { patterns });
  
  return withCache(cacheKey, async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/powerlines/syn`, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({ patterns })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new HttpError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
      }
      
      return response.json();
    } catch (error) {
      console.error('Error querying powerlines:', error);
      throw error;
    }
  });
};

export const getAspect = async (bodies, aspect, type) => {
  const cacheKey = getCacheKey('aspect', { bodies, aspect, type });
  
  return withCache(cacheKey, async () => {
    const url = `${BACKEND_URL}/aspect?bodies=${encodeURIComponent(bodies.join(','))}&aspect=${encodeURIComponent(aspect)}&type=${encodeURIComponent(type)}`;
    const response = await fetch(url, { headers: defaultHeaders });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      console.log("error getting aspect ", errorData.message, `HTTP error! status: ${response.status}`);
    }
    
    return response.json();
  });
};

export const getChart = async (latitude, longitude, timestamp) => {
  const cacheKey = getCacheKey('chart', { latitude, longitude, timestamp });

  return withCache(cacheKey, async () => {
    const url = new URL(`${BACKEND_URL}/astronomy/chart`);
    url.searchParams.append('latitude', latitude.toString());
    url.searchParams.append('longitude', longitude.toString());
    url.searchParams.append('timestamp', timestamp.toString());

    try {
      const response = await fetch(url, { headers: defaultHeaders });
      if (!response.ok) {
        let errorMessage = `HTTP error! status: ${response.status}`;
        try {
          const errorData = await response.json();
          if (errorData.message) errorMessage = errorData.message;
        } catch(e) {
          console.error('Error fetching chart:', e);
        }
        throw new HttpError(errorMessage, response.status);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching chart:', error);
      throw error;
    }
  });
};

export const buildChart = async (latitude, longitude, timestamp, timezone) => {
  const cacheKey = getCacheKey('buildChart', { latitude, longitude, timestamp, timezone });

  return withCache(cacheKey, async () => {
    const tz = await getTimezoneFromCoordinates(latitude, longitude);
    const url = new URL(`${BACKEND_URL}/astronomy/buildChart`);
    url.searchParams.append('latitude', latitude.toString());
    url.searchParams.append('longitude', longitude.toString());
    url.searchParams.append('timestamp', timestamp.toString());
    url.searchParams.append('tz', tz.toString());

    try {
      const response = await fetch(url, { headers: defaultHeaders });
      if (!response.ok) {
        let errorMessage = `HTTP error! status: ${response.status}`;
        try {
          const errorData = await response.json();
          if (errorData.message) errorMessage = errorData.message;
        } catch (e) {
          console.error('Error fetching chart:', e);
        }
        throw new HttpError(errorMessage, response.status);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching chart:', error);
      throw error;
    }
  });
};

export const doCalculatePaths = async (bodies, timestamp, chartIndex) => {
  const cacheKey = getCacheKey('paths', { bodies, timestamp, chartIndex });
  console.log(bodies, timestamp, chartIndex)
  return withCache(cacheKey, async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/cartography/paths?timestamp=${timestamp}&chartIndex=${chartIndex}`, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({ bodies })
      });
      
      if (!response.ok) {
        let errorMessage = `HTTP error! status: ${response.status}`;
        try {
          const errorData = await response.json();
          if (errorData.message) errorMessage = errorData.message;
        } catch (e) {
          console.error('Error calculating paths:', e);
        }
        throw new HttpError(errorMessage, response.status);
      }
      return await response.json();
    } catch (error) {
      console.error('Error calculating paths:', error);
      throw error;
    }
  });
};

export const doCalculateSynastryParans = async (paths1, paths2, chartIndex1, chartIndex2, timestamp1, timestamp2) => {
  const cacheKey = getCacheKey('synastry-parans', { paths1, paths2, chartIndex1, chartIndex2 });
  
  return withCache(cacheKey, async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/cartography/synParans`, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({ 
          paths1,
          paths2,
          chartIndex1,
          chartIndex2,
          timestamp1,
          timestamp2
        })
      });
      
      if (!response.ok) {
        let errorMessage = `HTTP error! status: ${response.status}`;
        try {
          const errorData = await response.json();
          if (errorData.message) errorMessage = errorData.message;
        } catch (e) {
          console.error('Error calculating synastry parans:', e);
        }
        throw new HttpError(errorMessage, response.status);
      }
      return await response.json();
    } catch (error) {
      console.error('Error calculating synastry parans:', error);
      throw error;
    }
  });
};

export const calculateAsteroids = async (timestamp) => {
  try {
    const response = await fetch(`${BACKEND_URL}/astronomy/ast?ts=${timestamp}`, {
      method: 'GET',
      headers: defaultHeaders
    });
    
    if (!response.ok) {
      throw new Error(`API call failed: ${response.status}`);
    }
    const asteroids = await response.json()
    
    return asteroids;
  } catch (error) {
    console.error('Error calculating asteroids:', error);
    throw error;
  }
};

// New transit endpoints
export const calculateTransits = async (startDate, endDate, bodies, step) => {
  const cacheKey = getCacheKey('transits', { startDate, endDate, bodies, step });
  
  return withCache(cacheKey, async () => {
    try {
      const requestBody = {
        startDate,
        endDate,
        step
      };
      
      // Only include bodies if it's defined
      if (bodies !== undefined) {
        requestBody.bodies = bodies;
      }

      const response = await fetch(`${BACKEND_URL}/transits/calculate`, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new HttpError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
      }
      
      return response.json();
    } catch (error) {
      console.error('Error calculating transits:', error);
      throw error;
    }
  });
};

export const calculateNatalTransits = async (startDate, endDate, timestamp, latitude, longitude, timezone, bodies, step) => {
  const cacheKey = getCacheKey('natal-transits', { startDate, endDate, timestamp, latitude, longitude, timezone, bodies, step });
  
  return withCache(cacheKey, async () => {
    try {
      const requestBody = {
        startDate,
        endDate,
        timestamp,
        latitude,
        longitude,
        timezone
      };
      
      // Only include optional parameters if they're defined
      if (bodies !== undefined) {
        requestBody.bodies = bodies;
      }
      if (step !== undefined) {
        requestBody.step = step;
      }

      const response = await fetch(`${BACKEND_URL}/transits/calculate-natal`, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new HttpError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
      }
      
      return response.json();
    } catch (error) {
      console.error('Error calculating natal transits:', error);
      throw error;
    }
  });
};

export const getCurrentPositions = async (bodies) => {
  const cacheKey = getCacheKey('current-positions', { bodies });
  
  return withCache(cacheKey, async () => {
    try {
      const url = new URL(`${BACKEND_URL}/transits/current`);
      if (bodies) {
        url.searchParams.append('bodies', bodies.join(','));
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: defaultHeaders
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new HttpError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
      }
      
      return response.json();
    } catch (error) {
      console.error('Error getting current positions:', error);
      throw error;
    }
  });
};

export const getPositionsForDate = async (date, bodies) => {
  const cacheKey = getCacheKey('positions-for-date', { date, bodies });
  
  return withCache(cacheKey, async () => {
    try {
      const requestBody = { date };
      if (bodies !== undefined) {
        requestBody.bodies = bodies;
      }

      const response = await fetch(`${BACKEND_URL}/transits/date`, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new HttpError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
      }
      
      return response.json();
    } catch (error) {
      console.error('Error getting positions for date:', error);
      throw error;
    }
  });
};

export const getCities = async (latitude, longitude, radius, population) => {
  const cacheKey = getCacheKey('getCities', { latitude, longitude, radius, population });

  return withCache(cacheKey, async () => {
    const tz = await getTimezoneFromCoordinates(latitude, longitude);
    const url = new URL(`${BACKEND_URL}/cartography/cities`);
    url.searchParams.append('lat', latitude.toString());
    url.searchParams.append('lng', longitude.toString());
    url.searchParams.append('rad', radius.toString());
    url.searchParams.append('p', population.toString());

    try {
      const response = await fetch(url, { headers: defaultHeaders });
      if (!response.ok) {
        let errorMessage = `HTTP error! status: ${response.status}`;
        try {
          const errorData = await response.json();
          if (errorData.message) errorMessage = errorData.message;
        } catch (e) {
          console.error('Error fetching chart:', e);
        }
        throw new HttpError(errorMessage, response.status);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching chart:', error);
      throw error;
    }
  });
};