
import tzlookup from 'tz-lookup';
import { isEqual } from 'lodash';
import { useRef } from 'react';
/**
 * Updates longitude and latitude form fields based on selected city and country
 * Searches through a predefined array of countries/cities to find matching coordinates
 */
export const getLocation = (selectedCity, selectedCountry) => {
    // Get input values from form
    // const selectedCity = $("#city").val();
    // const selectedCountry = $("#country").val();
    
    // Search through countries array for matching location
    for (let i = 0; i < document.countries.length; i++) {
        const location = document.countries[i];
        
        // Check if current location matches selected city and country
        if (location.country === selectedCountry && 
            location.name === selectedCity) {
            
            // Update longitude and latitude fields
            return location
            // $("#longitude").val(location.lng);
            // $("#latitude").val(location.lat);
            //return;
        }
    }
};

export const getCities = (selectedCountry, location) => {
   // Reset location fields
//    $("#longitude").val(0);
//    $("#latitude").val(0);
//    $("#city").val("");
   
   // Get selected country
   //const selectedCountry = $("#country").val();
   
   // Clear and initialize city dropdown with default option
   const cityDropdown = document.getElementById("citylist");
   cityDropdown.innerHTML = ''
   let newOption = document.createElement('option');
    newOption.value = '';
    newOption.textContent = '--Select City--';
    cityDropdown.add(newOption);
   
   // Populate cities for selected country
   document.countries.forEach(location => {
       if (location.country === selectedCountry) {
            let newOption = document.createElement('option');
            newOption.value = '';
            newOption.textContent = location.name;
            cityDropdown.add(newOption);
        //     cityDropdown.append(
        //        `<option>${location.name}</option>`
        //    );
       }
   });
};

export function checkForNaN(arr) {
    const positions = [];
    
    for (let i = 0; i < arr.length; i++) {
        const row = arr[i];
        if (!Array.isArray(row)) {
            throw new Error(`Element at index ${i} is not an array`);
        }
        
        for (let j = 0; j < row.length; j++) {
            if (Number.isNaN(row[j])) {
                positions.push([i, j]);
            }
        }
    }
    
    return {
        hasNaN: positions.length > 0,
        positions
    };
}




export const getTimezoneOffset = (timezone, date) => {
    //console.log(timezone, date)
    try {
      const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        timeZoneName: 'longOffset',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
      
      const parts = formatter.formatToParts(date);
      const timeZonePart = parts.find(part => part.type === 'timeZoneName');
      return timeZonePart ? timeZonePart.value : '';
    } catch (error) {
      console.log('Error getting timezone offset:', error);
      return '';
    }
  };

  



export function getTimezoneFromCoordinates(lat, lng) {
    try {
        
      return tzlookup(lat, lng);
    } catch (error) {
        
      return getDefaultTimezone(lng);
    }
  }
  

const getDefaultTimezone = (longitude) => {
    
 const offset = Math.round(longitude / 15);
 const tz = `Etc/GMT${offset >= 0 ? '-' : '+'}${Math.abs(offset)}`
 return tz;
}




export const dateToUnix = (date, time, timezone) => {
    //console.log("DATE TO UNIX", date, time, timezone)
    try {
        const dateTimeObj = new Date(`${date}T${time}`);
        const tzDate = new Date(dateTimeObj.toLocaleString('en-US', { timeZone: timezone }));
        const offset = dateTimeObj.getTime() - tzDate.getTime();
        
        dateTimeObj.setTime(dateTimeObj.getTime() + offset);
        
        const timestamp = dateTimeObj.getTime();
        
        return timestamp;
    } catch (error) {
        console.error('Error processing date:', error);
        return false;
    }
}

export const unixToDate = (timestamp, timezone) => {
    //console.log('UNIX TO DATE', timestamp, timezone)
    try {
        // Create a date object in the specified timezone
        const dateObj = new Date(timestamp);
        const options = { timeZone: timezone };

        //console.log(timezone, dateObj.toLocaleString('en-US', options))

        // Get date components
        const dateString = dateObj.toLocaleDateString('en-US', {
            ...options,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        // Get time components
        const timeString = dateObj.toLocaleTimeString('en-US', {
            ...options,
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });

        // Convert date string from MM/DD/YYYY to YYYY-MM-DD
        const [month, day, year] = dateString.split('/');
        const formattedDate = `${year}-${month}-${day}`;

        return {
            dateString: formattedDate,
            timeString: timeString
        };
    } catch (error) {
        console.error('Error converting timestamp:', error);
        return false;
    }
}

// Or if you need to handle any IANA timezone:
function getGMTOffset(ianaTimezone) {
    try {
        const date = new Date();
        const utcOffset = date.toLocaleTimeString('en-US', {
            timeZone: ianaTimezone,
            timeZoneName: 'shortOffset'
        }).split(' ').pop();
        return utcOffset
    } catch (error) {
        console.error('Error getting GMT offset:', error);
        return null;
    }
}

export const parseIntSafe = (value, defaultValue) => {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? defaultValue : parsed;
  };

  export const parseCoordinates = (lat, lng) => ({
    latitude: lat ? parseFloat(lat) : null,
    longitude: lng ? parseFloat(lng) : null,
  });


  export const parseFloatSafe = (value, defaultValue) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? defaultValue : parsed;
  };


export const useMemoWithDeepEqual = (value, deps) => {
    const ref = useRef(value);
    if (!isEqual(value, ref.current)) ref.current = value;
    return ref.current;
}