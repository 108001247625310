import { useMemo } from 'react';
import { useBodiesData } from '../contexts/bodiesContext';

export const useWheelConfigs = ({ selectedAspect, setSelectedAspect, aspectPaneProps }) => {
  const {
    bodies,
    bodies2,
    getBodiesIndexNo,
    getAngleBodiesNo,
    getPartsNo,
    getCuspsNo,
    getPAspectsNo,
    getAsteroidsNo,
  } = useBodiesData();

  const wheelConfigs = useMemo(() => ({
    chart1: {
      bodies,
      angleBodies: getAngleBodiesNo(1),
      parts: getPartsNo(1),
      cusps: getCuspsNo(1),
      aspects: getPAspectsNo(1),
      asteroids: getAsteroidsNo(1),
      title: "Chart 1",
      selectedAspect,
      aspectSearch: aspectPaneProps?.aspectSearch,
      setSelectedAspect
    },
    chart2: {
      bodies: bodies2,
      angleBodies: getAngleBodiesNo(2),
      parts: getPartsNo(2),
      cusps: getCuspsNo(2),
      aspects: getPAspectsNo(2),
      asteroids: getAsteroidsNo(2),
      title: "Chart 2",
      selectedAspect,
      aspectSearch: aspectPaneProps?.aspectSearch,
      setSelectedAspect
    },
    relocated1: {
      bodies,
      angleBodies: getAngleBodiesNo(3),
      parts: getPartsNo(3),
      cusps: getCuspsNo(3),
      aspects: getPAspectsNo(3),
      asteroids: getAsteroidsNo(3),
      title: "Relocated Chart 1",
      selectedAspect,
      aspectSearch: aspectPaneProps?.aspectSearch,
      setSelectedAspect
    },
    relocated2: {
      bodies: bodies2,
      angleBodies: getAngleBodiesNo(4),
      parts: getPartsNo(4),
      cusps: getCuspsNo(4),
      aspects: getPAspectsNo(4),
      asteroids: getAsteroidsNo(4),
      title: "Relocated Chart 2",
      selectedAspect,
      aspectSearch: aspectPaneProps?.aspectSearch,
      setSelectedAspect
    },
    synastry: {
      bodies,
      angleBodies: getAngleBodiesNo(1),
      parts: getPartsNo(1),
      cusps: getCuspsNo(1),
      aspects: getPAspectsNo(1),
      asteroids: getAsteroidsNo(1),
      synastry: {
        bodies: [...bodies2, ...getPartsNo(2)],
        aspects: getPAspectsNo(2),
        angleBodies: getAngleBodiesNo(2),
        cusps: getCuspsNo(2),
        asteroids: getAsteroidsNo(2),
        metadata: {},
        synastryAspects: aspectPaneProps?.synastryAspects
      },
      selectedAspect,
      aspectSearch: aspectPaneProps?.aspectSearch,
      setSelectedAspect
    },
    relocatedSynastry: {
      bodies,
      angleBodies: getAngleBodiesNo(3),
      parts: getPartsNo(3),
      cusps: getCuspsNo(3),
      aspects: getPAspectsNo(3),
      asteroids: getAsteroidsNo(3),
      synastry: {
        bodies: [...bodies2, ...getPartsNo(4)],
        aspects: getPAspectsNo(4),
        angleBodies: getAngleBodiesNo(4),
        cusps: getCuspsNo(4),
        asteroids: getAsteroidsNo(4),
        metadata: {},
        synastryAspects: aspectPaneProps?.relocSynastryAspects
      },
      selectedAspect,
      aspectSearch: aspectPaneProps?.aspectSearch,
      setSelectedAspect
    },
    davison: {
      bodies: getBodiesIndexNo(7),
      angleBodies: getAngleBodiesNo(7),
      parts: getPartsNo(7),
      cusps: getCuspsNo(7),
      aspects: getPAspectsNo(7),
      asteroids: getAsteroidsNo(7),
      title: "Davison Composite",
      selectedAspect,
      aspectSearch: aspectPaneProps?.aspectSearch,
      setSelectedAspect
    }
  }), [
    bodies, bodies2,
    getAngleBodiesNo, getBodiesIndexNo,
    getPartsNo, getCuspsNo,
    getPAspectsNo, getAsteroidsNo,
    aspectPaneProps?.synastryAspects,
    aspectPaneProps?.relocSynastryAspects,
    selectedAspect,
    aspectPaneProps?.aspectSearch
  ]);

  // Helper to get configs in array form for components that need ordered access
  const getConfigsArray = () => [
    wheelConfigs.chart1,
    wheelConfigs.chart2,
    wheelConfigs.relocated1,
    wheelConfigs.relocated2,
    wheelConfigs.synastry,
    wheelConfigs.relocatedSynastry,
    wheelConfigs.davison
  ];

  return {
    wheelConfigs,
    getConfigsArray
  };
};
