import React, { memo } from 'react';
import ZodiacWheel from './ZodiacWheel';

const WheelConfig = memo(({ 
  bodies,
  angleBodies,
  parts,
  cusps,
  aspects,
  asteroids,
  title,
  synastry,
  size = 300,
  selectedAspect,
  aspectSearch,
  setSelectedAspect
}) => {
  const config = {
    bodies: [...bodies, ...(angleBodies || []), ...(parts || [])],
    cusps,
    aspects,
    ascDeg: angleBodies?.[0]?.degree,
    asteroids,
    title,
    synastry,
    size,
    selectedAspect,
    aspectSearch,
    setSelectedAspect
  };

  if (synastry) {
    return (
      <div style={{ marginTop: -8, padding: 0, borderRadius: 320 }}>
        <ZodiacWheel {...config} />
      </div>
    );
  }

  return <ZodiacWheel {...config} />;
}, (prevProps, nextProps) => {
  // Custom comparison function to prevent unnecessary re-renders
  const compareArrays = (arr1 = [], arr2 = []) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => {
      const keys = Object.keys(item || {});
      return keys.every(key => item[key] === arr2[index]?.[key]);
    });
  };

  return (
    compareArrays(prevProps.bodies, nextProps.bodies) &&
    compareArrays(prevProps.angleBodies, nextProps.angleBodies) &&
    compareArrays(prevProps.parts, nextProps.parts) &&
    compareArrays(prevProps.cusps, nextProps.cusps) &&
    compareArrays(prevProps.aspects, nextProps.aspects) &&
    compareArrays(prevProps.asteroids, nextProps.asteroids) &&
    prevProps.title === nextProps.title &&
    prevProps.size === nextProps.size &&
    prevProps.selectedAspect === nextProps.selectedAspect &&
    compareArrays(prevProps.aspectSearch, nextProps.aspectSearch) &&
    JSON.stringify(prevProps.synastry || {}) === JSON.stringify(nextProps.synastry || {})
  );
});

WheelConfig.displayName = 'WheelConfig';

export default WheelConfig;
