import React, { useRef, useEffect, useState } from 'react';
import { useDualBirthData } from '../../contexts/birthContext';
import { useBodyViewSettings } from '../../contexts/bodyViewContext';
import { useViewData } from '../../contexts/viewContext';
import { getAllTimestamps, getPeakTransitsForTimestamp } from '../../scripts/transits';
import { bodyReference } from "../../scripts/chart";
import { ASPECT_TYPES } from "../../scripts/aspects";
import { elementColors, getSignPosition } from '../../data/zodiacConstants';
import { OptionButton } from '../modalsmenus/OptionsPane';
import { ToggleSwitch } from '../modalsmenus/LineViewSettings';
import { isMobile } from 'react-device-detect';

const TransitList = ({ transits, enabledAspects, transitSettings }) => {
  const { isTransitVisible } = useBodyViewSettings();
  const filteredTransits = transits
    .filter(transit => Math.abs(transit.orb) <= 1.8)
    .filter(transit => 
      isTransitVisible(transit.natalBody) && isTransitVisible(transit.transitBody)
    )
    .filter(transit => enabledAspects[transit.aspectType.toLowerCase()]);

    const {bodyColors, showSigns} = transitSettings

  return filteredTransits.length > 0 ? (
    <div style={{ fontSize: 14 }}>
      {filteredTransits.map((transit, idx) => {
        const natalBodyInfo = bodyReference[transit.natalBody];
        const transitBodyInfo = bodyReference[transit.transitBody];
        const aspectInfo = ASPECT_TYPES[transit.aspectType.toLowerCase()];
        
        if (!natalBodyInfo || !transitBodyInfo || !aspectInfo) return null;

        const transitSignInfo = getSignPosition(transit.transitDegree);
        const natalSignInfo = getSignPosition(transit.natalDegree);
        const aspectColor = aspectInfo.colorFunc;

        return (
          <div key={idx} style={{ 
            marginBottom: 4,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'center',
            gap: 8, border: bodyColors ? `1px solid rgba(255,255,255,0.0)` : `1px solid ${aspectColor(1)}`,
            background: bodyColors ? `rgba(255,255,255,0.1)` : `${aspectColor(.1)}`,
            borderRadius: 4,
            paddingTop: showSigns ? 4 : 8, paddingBottom: showSigns ? 4 : 8,
          }}>
            <div style={{ display: 'flex', alignItems: 'center',}}>
            <div style={{ display: 'flex', alignItems: 'center', width: 24, maxWidth: 24, gap: 2, justifyContent: 'flex-end' }}>
              <span style={{
                color: bodyColors ? transitBodyInfo.color : 'white',
                textAlign: 'center',
                lineHeight: '12px'
              }} className='symbolText'>
                {transitBodyInfo.symbol}
              </span>
              {showSigns && <span style={{
                color: bodyColors ? elementColors[transitSignInfo.sign.element] : 'white',
                opacity: 0.7,
                fontSize: '10px'
              }} className='symbolText'>
                {transitSignInfo.sign.symbol}
              </span>}
              </div>
            </div>
            <span style={{
              color: 'white',
              textAlign: 'center',
              lineHeight: '12px'
            }} className='symbolText'>
              {aspectInfo.symbol}
            </span>
            <div style={{ display: 'flex', alignItems: 'center',}}>
            <div style={{ display: 'flex', alignItems: 'center', width: 24, maxWidth: 24, gap: 2, justifyContent: 'flex-start' }}>
              <span style={{ 
                color: bodyColors ? natalBodyInfo.color : 'white', 
                textAlign: 'center',
                lineHeight: '12px',
                fontSize: natalBodyInfo.symbol && natalBodyInfo.symbol.length === 2 ? '10px' : '12px'
              }} className='symbolText'>
                {natalBodyInfo.symbol}
              </span>
              {showSigns && <span style={{
                color: bodyColors ? elementColors[natalSignInfo.sign.element] : 'white',
                opacity: 0.7,
                fontSize: '10px'
              }} className='symbolText'>
                {natalSignInfo.sign.symbol}
              </span>}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div style={{ 
      color: 'rgba(255,255,255,0.3)',
      fontStyle: 'italic',
      fontSize: 12,
      textAlign: 'center',
      padding: '8px 0'
    }}>
      none
    </div>
  );
};

const TransitCard = ({ timestamp, birth1Transits, transitSettings, birth2Transits, midpointTransits, showSecond, enabledAspects }) => {
  const date = new Date(parseInt(timestamp));
  const formattedDate = date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  });

  const isToday = new Date().toDateString() === date.toDateString();
  

  return (
    <div 
      id={`transit-${timestamp}`}
      style={{
        marginBottom: 16,
        padding: 4,
        borderRadius: 4,
        backgroundColor: 'rgba(255,255,255,0)',
        borderLeft: isToday ? '3px solid white' : '3px solid transparent'
      }}
    >
      <div style={{ 
        color: 'rgba(255,255,255,.5)',
        marginBottom: 8,
        borderBottom: '1px solid rgba(255,255,255,0.2)',
        paddingBottom: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}>
        <span>{formattedDate}</span>
      </div>
      <div style={{
        display: 'grid',
        gridTemplateColumns: showSecond ? '1fr 1fr 1fr' : '1fr',
        gap: 16
      }}>
        <div>
          <div style={{
            color: 'rgba(255,255,255,.5)',
            fontSize: 12,
            marginBottom: 4,
            textAlign: 'center'
          }}>
            Chart 1
          </div>
          <TransitList transits={birth1Transits} enabledAspects={enabledAspects} transitSettings={transitSettings}/>
        </div>
        {showSecond && (
          <div>
            <div style={{
              color: 'rgba(255,255,255,.5)',
              fontSize: 12,
              marginBottom: 4,
              textAlign: 'center'
            }}>
              Chart 2
            </div>
            <TransitList transits={birth2Transits} enabledAspects={enabledAspects} transitSettings={transitSettings}/>
          </div>
        )}
        {showSecond && (
          <div>
            <div style={{
              color: 'rgba(255,255,255,.5)',
              fontSize: 12,
              marginBottom: 4,
              textAlign: 'center'
            }}>
              Composite
            </div>
            <TransitList transits={midpointTransits} enabledAspects={enabledAspects} transitSettings={transitSettings}/>
          </div>
        )}
      </div>
    </div>
  );
};

const AspectToggle = ({ aspectKey, aspectInfo, enabled, onToggle }) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '4px 8px',
    cursor: 'pointer',
    opacity: enabled ? 1 : 0.5,
    transition: 'opacity 0.2s ease'
  }} onClick={onToggle}>
    <div style={{
      width: 20,
      height: 20,
      borderRadius: 4,
      border: '1px solid rgba(255,255,255,0.3)',
      backgroundColor: enabled ? aspectInfo.color : 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.2s ease'
    }}>
      {enabled && <span style={{ color: 'black' }}>✓</span>}
    </div>
    <span className="symbolText" style={{ fontSize: 16 }}>{aspectInfo.symbol}</span>
    <span style={{ textTransform: 'capitalize' }}>{aspectKey}</span>
  </div>
);

const TransitModule = ({heightMinus=0}) => {
  const { birth1, birth2, midpoint } = useDualBirthData();
  const { getOptions } = useViewData();
  const showSecond = getOptions().second;
  const containerRef = useRef(null);

  const [bodyColors, setBodyColors] = useState(false);
  const [showSigns, setShowSigns] = useState(true);

  
  // Initialize enabled aspects state
  const [enabledAspects, setEnabledAspects] = useState(() => 
    Object.keys(ASPECT_TYPES).reduce((acc, key) => ({
      ...acc,
      [key]: key === 'quincunx' ? false : true
    }), {})
  );

  const { getIsMobile } = useViewData()

  const toggleAspect = (aspectKey) => {
    setEnabledAspects(prev => ({
      ...prev,
      [aspectKey]: !prev[aspectKey]
    }));
  };

  useEffect(() => {
    if (birth1.transits || (showSecond && birth2.transits)) {
      const timestamps = getAllTimestamps(birth1.transits || {});
      if (showSecond && birth2.transits) {
        const birth2Timestamps = getAllTimestamps(birth2.transits);
        timestamps.push(...birth2Timestamps);
      }
      const uniqueTimestamps = [...new Set(timestamps)].sort((a, b) => a - b);

      if (containerRef.current && uniqueTimestamps.length > 0) {
        const now = new Date().getTime();
        // Find the timestamp closest to now
        const closestTimestamp = uniqueTimestamps.reduce((prev, curr) => {
          return Math.abs(curr - now) < Math.abs(prev - now) ? curr : prev;
        });
        
        // Use requestAnimationFrame to ensure DOM is ready
        requestAnimationFrame(() => {
          const element = document.getElementById(`transit-${closestTimestamp}`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
      }
    }
  }, [birth1.transits, birth2.transits, showSecond]);
  
  if (!birth1.transits && (!showSecond || !birth2.transits)) {
    return (
      <div style={{ 
        padding: 20,
        textAlign: 'center',
        color: 'rgba(255,255,255,0.5)'
      }}>
        {birth1.isLoadingTransits || (showSecond && birth2.isLoadingTransits) 
          ? 'Loading transits...' 
          : 'No transit data available'}
      </div>
    );
  }

  const timestamps = getAllTimestamps(birth1.transits || {});
  if (showSecond && birth2.transits) {
    const birth2Timestamps = getAllTimestamps(birth2.transits);
    timestamps.push(...birth2Timestamps);
  }
  if (showSecond && midpoint.transits) {
    const midpointTransits = getAllTimestamps(midpoint.transits);
    timestamps.push(...midpointTransits);
  }
  const uniqueTimestamps = [...new Set(timestamps)].sort((a, b) => a - b);
  

  const transitSettings = {bodyColors, showSigns};

  return (
    <div style={{ 
      padding: 8,
      maxHeight: `calc(100% - ${0}px)`,
      overflowY: 'scroll',
      overflowX: 'hidden',
      position: 'relative',
    }} ref={containerRef}>
      <div style={{
        color: 'rgba(255,255,255,.5)',
        textAlign: 'center',
        width: '100%',
        marginBottom: 16
      }}>
        Transit Calendar
      </div>
      <div>
        {uniqueTimestamps.map(timestamp => (
          <TransitCard
            key={timestamp}
            timestamp={timestamp}
            birth1Transits={getPeakTransitsForTimestamp(birth1.transits || {}, timestamp)}
            birth2Transits={getPeakTransitsForTimestamp(birth2.transits || {}, timestamp)}
            midpointTransits={getPeakTransitsForTimestamp(midpoint.transits || {}, timestamp)}
            showSecond={showSecond}
            enabledAspects={enabledAspects}
            transitSettings={transitSettings}
          />
        ))}
      </div>
      <div style={{
        width: '100%',
        position: 'sticky',
        bottom: -0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        backdropFilter: 'blur(2px)',
        color: 'white',
        padding: 16,
        borderTop: '1px solid rgba(255,255,255,0.1)',
        display: 'flex', flexDirection: 'column', alignItems: 'center',
      }}>
          <div style={{
            display: 'flex',flexDirection: 'row', gap: 4, fontSize: 10, marginBottom: 8}}>
              <div>planet colors</div>
            <ToggleSwitch isOn={() => bodyColors} onClick={() => setBodyColors(!bodyColors)} />
            <div style={{marginLeft: 8}}>signs</div>
            <ToggleSwitch isOn={() => showSigns} onClick={() => setShowSigns(!showSigns)} />
          </div>
        <div style={{
          display: 'flex',flexDirection: 'row', 
          
          gap: 4
        }}>
          {Object.entries(ASPECT_TYPES).map(([key, info]) => (
            <OptionButton
              key={key}
              isOn={enabledAspects[key]}
              onClick={() => toggleAspect(key)}
              symbol={info.symbol}
            />
            // <AspectToggle
            //   key={key}
            //   aspectKey={key}
            //   aspectInfo={info}
            //   enabled={enabledAspects[key]}
            //   onToggle={() => toggleAspect(key)}
            // />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransitModule;
