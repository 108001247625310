import React, { createContext, useContext, useEffect, useState } from 'react';
import { useBodiesData } from './bodiesContext';
import { findRelatedAspectsToBody } from '../scripts/aspects';
import { getHouseForDegree } from '../components/planetarytables/HousePositionCard';
import { getSignPosition } from '../data/zodiacConstants';

const SummaryContext = createContext(null);

export const SummaryProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSummaryLine, setSelectedSummaryLine] = useState(null);
  const [selectedInfo, setSelectedInfo] = useState({});
  const [selectedInterpretations, setSelectedInterpretations] = useState({});

  const { getCombinedBodiesNo, getAspectsNo, getCuspsNo } = useBodiesData()

  useEffect(() => {
    if (selectedSummaryLine) {
        const chartNo = selectedSummaryLine.synastry || selectedSummaryLine.marker.index;
        const bodies = getCombinedBodiesNo(chartNo);
        const relocatedBodies = getCombinedBodiesNo(chartNo+2);
        const cusps = getCuspsNo(chartNo)
        const relocatedCusps = getCuspsNo(chartNo+2);

        const bodyName = selectedSummaryLine?.marker?.body?.name && selectedSummaryLine?.marker?.body?.name.includes('Syn') ? 
            selectedSummaryLine?.marker?.body?.name.split('-Syn')[0] : selectedSummaryLine?.marker?.body?.name;

        const body = bodies.find(body => body.name.toLowerCase() === bodyName.toLowerCase());
        const sign = getSignPosition(body?.degree);
        

        const aspects = getAspectsNo(chartNo);

        let relatedAspects = findRelatedAspectsToBody(bodyName, chartNo, aspects)

        const house = getHouseForDegree(body.degree, cusps);
        const relocatedHouse = getHouseForDegree(body.degree, relocatedCusps);

        if (selectedSummaryLine.type === 'PARANS') {
            const body2 = selectedSummaryLine?.marker?.body2;
            const bodyName2 = selectedSummaryLine?.marker?.body2?.name
            const index2 = selectedSummaryLine?.index2 ? selectedSummaryLine?.index2 : chartNo;
            const aspects2 = getAspectsNo(index2);

            const relatedAspects2 = findRelatedAspectsToBody(bodyName2, index2, aspects2);
            // console.log('relatedAspects2', relatedAspects2)
            if (relatedAspects2 && relatedAspects2.length > 0) relatedAspects = [...relatedAspects, ...relatedAspects2]
        }
        setSelectedInfo({
            body,
            bodyName,
            chartNo,
            sign,
            relocatedBodies,
            cusps,
            houses: {
                natal: house,
                relocated: relocatedHouse,
            }, 
            relocatedCusps,
            bodies,
            aspects,
            relatedAspects,
        })
    }

  }, [selectedSummaryLine])

  const toggleSummaryModal = () => {
    if (isOpen) {
        setSelectedSummaryLine(null)
        setSelectedInfo({})
        setSelectedInterpretations([]);
    }
    setIsOpen(!isOpen)
  };
  const closeSummaryModal = () => {
    setIsOpen(false);
    setSelectedSummaryLine(null);
    setSelectedInfo({})
    setSelectedInterpretations([]);
  };


  const value = {
    isOpen,
    selectedSummaryLine,
    setSelectedSummaryLine: (line) => {
      setSelectedSummaryLine(line);
      setIsOpen(true);
    },
    toggleSummaryModal,
    closeSummaryModal,
    selectedInfo,
    selectedInterpretations,
    setSelectedInterpretations,
  };

  return <SummaryContext.Provider value={value}>{children}</SummaryContext.Provider>;
};

export const useSummary = () => {
  const context = useContext(SummaryContext);
  if (!context) throw new Error('useSummary must be used within SummaryProvider');
  return context;
};

export default SummaryProvider;