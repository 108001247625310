import React, { useMemo, memo } from 'react';
import { elementColors, getSignPosition, signs } from '../data/zodiacConstants';
import { ASPECT_TYPES, getNormalizedBodyKey } from '../scripts/aspects';

import useViewport from '../hooks/useViewport';

import { isAspectInSearch } from './aspects/aspects.helpers';
import { useBodyViewSettings } from '../contexts/bodyViewContext';
import { CircleAroundSymbol } from './globeComponents';


const formatNumber = (num) => num.toString().padStart(2, '0');



export function calculateAspectsBetweenSets(bodies1, bodies2, sourceNo1, sourceNo2) {
    const aspects = []
    // Helper function to calculate the smallest angle between two degrees
    const getAngleDifference = (deg1, deg2) => {
      const diff = Math.abs(deg1 - deg2) % 360;
      return diff > 180 ? 360 - diff : diff;
    };
  
    // For each body in first set
    bodies1.forEach(body1 => {
      // Compare with each body in second set
        if(!body1.name.includes('Part'))
        bodies2.forEach(body2 => {
        // Calculate angle between the bodies
            const angleDiff = getAngleDifference(body1.degree, body2.degree);
  
        // Check each aspect type
            Object.entries(ASPECT_TYPES).forEach(([aspectKey, definition]) => {
            const orb = Math.abs(angleDiff - definition.angle);
            if(!body2.name.includes('Part') && orb <= definition.orb) {
                aspects.push({
                aspectKey,
                aspectLevel: definition.level,
                aspectLevelLabel: definition.level === 'major' ? 'Major' : 'Minor',
                label: aspectKey.charAt(0).toUpperCase() + aspectKey.slice(1),
                orb,
                orbUsed: definition.orb,
                point1Key: getNormalizedBodyKey(body1.name),
                point1Label: body1.name ? body1.name : body1.bodyName,
                point1Source: sourceNo1,
                point2Key: getNormalizedBodyKey(body2.name),
                point2Label: body2.name ? body2.name :body2.bodyName,
                point2Source: sourceNo2,
                });
            }
        });
      });
    });
    return aspects;
}
  

  
  // Add new AspectLine component
const AspectLine = memo(({ aspect, bodies, centerX, centerY, radius, bodies2=false, clickHandler, isSelected, selectedAspect, isInSearch }) => {
    const point1 = bodies.find(b => getNormalizedBodyKey(b.name.toLowerCase()) === aspect.point1Key);
    const secondBodies = (bodies2 && bodies2.length) ? bodies2 : bodies;
    const point2 = secondBodies.find(b => getNormalizedBodyKey(b.name.toLowerCase()) === aspect.point2Key);

    if (!point1 || !point2) return null;

    const angle1 = (30-point1.degree) * (Math.PI / 180);
    const angle2 = (30-point2.degree) * (Math.PI / 180);

    const x1 = centerX + Math.cos(angle1) * radius;
    const y1 = centerY + Math.sin(angle1) * radius;
    const x2 = centerX + Math.cos(angle2) * radius;
    const y2 = centerY + Math.sin(angle2) * radius;

    const aspectType = ASPECT_TYPES[aspect.aspectKey];
    if (!aspectType) return null;

    return (
        <line className='aspectLine' onClick={() => clickHandler(aspect)} style={{cursor: 'pointer', pointerEvents: 'auto'}}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={isSelected ? 'white' : isInSearch? 'orange' : selectedAspect ? 'rgba(255,255,255,.2)' : aspectType.color}
        strokeWidth={isSelected? 5 : isInSearch ? 3 : 1}//aspect.aspectLevel === "major" ? 2 : 1}
        opacity={1 - (aspect.orb / aspect.orbUsed)}
        />
    );
});

// Function to find clusters of overlapping bodies
const findClusters = (sorted, minAngle) => {
    // const MIN_ANGLE_DIFF = 5;
    const clusters = [];
    let currentCluster = [sorted[0]];

    for (let i = 1; i < sorted.length; i++) {
        const diff = sorted[i].degree - sorted[i-1].degree;
        if (diff < minAngle) {
            currentCluster.push(sorted[i]);
        } else {
            if (currentCluster.length > 0) {
                clusters.push(currentCluster);
            }
            currentCluster = [sorted[i]];
        }
    }
    
    if (currentCluster.length > 0) {
        clusters.push(currentCluster);
    }

    if (clusters.length > 1) {
        const firstCluster = clusters[0];
        const lastCluster = clusters[clusters.length - 1];
        const wraparoundDiff = (firstCluster[0].degree + 360) - lastCluster[lastCluster.length - 1].degree;
        if (wraparoundDiff < minAngle) {
            const combinedCluster = [...lastCluster, ...firstCluster];
            clusters.pop();
            clusters[0] = combinedCluster;
        }
    }

    return clusters;
};

// Function to optimize positions for overlapping items with multiple passes
const optimizePositions = (bodies, minAngle=5) => {
    if (!bodies.length) return [];
    //const MIN_ANGLE_DIFF = 7;

    const adjustPositions = (bodies) => {
        const sorted = [...bodies].sort((a, b) => a.degree - b.degree);
        const clusters = findClusters(sorted, minAngle);

        return sorted.map(body => {
            const cluster = clusters.find(c => c.includes(body));
            if (!cluster || cluster.length === 1) {
                return { ...body, displayDegree: body.degree };
            }

            const clusterCenter = cluster.reduce((sum, b) => sum + b.degree, 0) / cluster.length;

            const position = cluster.indexOf(body);
            const offset = (position - (cluster.length - 1) / 2) * minAngle;

            return {
                ...body,
                displayDegree: clusterCenter + offset
            };
        });
    };

    let prevResult = bodies;
    let newResult = adjustPositions(bodies);

    // Keep adjusting until no changes occur
    while (
        JSON.stringify(prevResult.map(b => b.displayDegree)) !==
        JSON.stringify(newResult.map(b => b.displayDegree))
    ) {
        prevResult = newResult;
        newResult = adjustPositions(newResult);
    }

    return newResult;
};

// Helper function to find clusters of overlapping bodies
// const findClusters = (sortedBodies) => {
//     const clusters = [];
//     let currentCluster = [];

//     for (let i = 0; i < sortedBodies.length; i++) {
//         const current = sortedBodies[i];
//         if (
//             currentCluster.length &&
//             Math.abs(current.degree - currentCluster[currentCluster.length - 1].degree) > 7
//         ) {
//             clusters.push(currentCluster);
//             currentCluster = [];
//         }
//         currentCluster.push(current);
//     }

//     if (currentCluster.length) {
//         clusters.push(currentCluster);
//     }

//     return clusters;
// };

// Move static calculations outside
const calculateRadii = (size, sizeFactor, scaleFactor) => {
    //const radius = ((size+sizeFactor)-24) / 2;

    const radius1 = ((size+0)-24) / 2;
    const radius2 = ((size+2*sizeFactor)-24) / 2;
    const radius3 = ((size-sizeFactor*1.25)-24) / 2;

    const factor = scaleFactor === 1 ? 0 : 4//0*sizeFactor/2;
    return {
        radius: radius1,
        centerX: radius2 + 12,
        centerY: radius2 + 12 ,
        symbolRadius: radius3 - 4,
        //centerX_s: radius1 + (scaleFactor!= 1 ? 24 : 12) - 0,
        centerX_s: radius1 + 12 - 0+factor,
        centerY_s: radius1 + 12 - 0+factor,
        referenceRadius: (size-24)/2,
    };
};

// Memoized ZodiacLine component
const ZodiacLine = memo(({ centerX, centerY, radius, index }) => {
    const angle = (index * 30) * (Math.PI / 180);
    const x2 = centerX + Math.cos(angle) * (radius+8);
    const y2 = centerY + Math.sin(angle) * (radius+8);
    const x3 = centerX + Math.cos(angle) * (radius - 12);
    const y3 = centerY + Math.sin(angle) * (radius - 12);
    
    return (
        <line
            x1={x2}
            y1={y2}
            x2={x3}
            y2={y3}
            stroke="white"
            strokeWidth="1"
            opacity="0.5"
        />
    );
});


const SynastryCusp = memo(({ centerX, centerY, degree, radius, outerRadius, index }) => {
    const angle = degree * (Math.PI / 180);
    
    // Calculate position for the house number (5 degrees after the cusp)
    const labelAngle = (degree - 6) * (Math.PI / 180);
    const labelRadius = outerRadius - 12; // Slightly inside the outer circle
    const labelX = centerX + Math.cos(labelAngle) * labelRadius;
    const labelY = centerY + Math.sin(labelAngle) * labelRadius;
    
    return (
        <>
            <line
                x1={centerX + Math.cos(angle) * radius}
                y1={centerY + Math.sin(angle) * radius}
                x2={centerX + Math.cos(angle) * outerRadius}
                y2={centerY + Math.sin(angle) * outerRadius}
                stroke="white"
                strokeWidth="1"
                opacity="0.33"
            />
            <text
                x={labelX}
                y={labelY}
                fill="white"
                opacity="0.33"
                fontSize="10"
                textAnchor="middle"
                dominantBaseline="middle"
                transform={`rotate(${degree + 90}, ${labelX}, ${labelY})`}
            >
                ←{index+1}
            </text>
        </>
    );
});


// Memoized ZodiacSymbol component
const ZodiacSymbol = memo(({ sign, index, centerX, centerY, symbolRadius, size }) => {
    const angle = (index * 30 + 15) * (Math.PI / 180);
    const x = centerX + Math.cos(angle) * symbolRadius;
    const y = centerY + Math.sin(angle) * symbolRadius;
    const symbolRotation = (index * 30 + 195);

    return (
        <div
            className="absolute select-none symbolText"
            style={{
                position: 'absolute',
                left: `${(x / size) * 100}%`,
                top: `${(y / size) * 100}%`,
                transform: `translate(-50%, -50%) rotate(${symbolRotation-90}deg)`,
                color: elementColors[sign.element],
                fontSize: 14,//`${size * 0.06}px`
            }}
        >
            {sign.symbol}
        </div>
    );
});

// Memoized CelestialBody component
const CelestialBody = memo(({ body, position, displayAngle, radii, centerX, centerY, size, totalRotation }) => {
    const elements = useMemo(() => [
        { content: `${formatNumber(position.minutes)}'`, radius: radii[0], size: 360 * 0.026 },
        { content: position.sign.symbol, radius: radii[1], size: 360 * 0.032, color: elementColors[position.sign.element], math: true },
        { content: `${formatNumber(position.degree)}°`, radius: radii[2], size: 360 * 0.03 },
        { content:  <CircleAroundSymbol circle={body.circle} symbol={body.symbol} color={body.color} />, radius: radii[3], size: 360 * 0.044, color: body.color, math: true }
    ], [body, position, radii]);
    
    return elements.map((element, elemIndex) => {
        const x = centerX + Math.cos(displayAngle) * element.radius;
        const y = centerY + Math.sin(displayAngle) * element.radius;
        
        return (
            <div
            //    key={`${body.bodyName}-${elemIndex}`}
            className={`absolute select-none ${element.math ? "symbolText" : ''}`}
                key={`${body.bodyName}-${element.content}-${elemIndex}`}
            
                style={{
                    position: 'absolute',
                    //fontFamily: element.math ?body.symbol.length > 1?'sans-serif' : 'Noto Sans Mono' : 'sans-serif',
                    left: `${(x / size) * 100}%`,
                    top: `${(y / size) * 100}%`,
                    transform: `translate(-50%, -50%) rotate(${-totalRotation}deg)`,
                    fontSize: `${element.math ? body.symbol.length ? element.size*.8: element.size : element.size*.8}px`,
                    color: element.color || 'rgba(255,255,255,.9)',
                    //fontWeight: 800,
                }}
            >
                {element.content}
            </div>
        );
    });
});
// Add after CelestialBody component
const CelestialBody2 = memo(({ body, position, displayAngle, radii, centerX, centerY, size, totalRotation }) => {
    // Adjust radii to go outward instead of inward
    const elements = useMemo(() => [
        { content: <CircleAroundSymbol circle={body.circle} symbol={body.symbol} color={body.color} />, radius: radii[0], size: 360 * 0.044, color: body.color, math: true },
        { content: `${formatNumber(position.degree)}°`, radius: radii[1], size: 360 * 0.03 },
        { content: position.sign.symbol, radius: radii[2], size: 360 * 0.032, color: elementColors[position.sign.element], math: true },
        { content: `${formatNumber(position.minutes)}'`, radius: radii[3], size: 360 * 0.026 }
    ], [body, position, radii]);

    return elements.map((element, elemIndex) => {
        const x = centerX + Math.cos(displayAngle) * element.radius;
        const y = centerY + Math.sin(displayAngle) * element.radius;
        
        return (
            <div
                className={`absolute select-none ${element.math ? "symbolText" : ''}`}
                key={`${body.bodyName}-${element.content}-${elemIndex}`}
                style={{
                    position: 'absolute',
                    //fontFamily: element.math ?body.symbol.length > 1?'sans-serif' : 'Noto Sans Mono' : 'sans-serif',
                    left: `${(x / size) * 100}%`,
                    top: `${(y / size) * 100}%`,
                    transform: `translate(-50%, -50%) rotate(${-totalRotation}deg)`,
                    fontSize: `${body.symbol.length > 1 ? element.size*.8 : element.size}px`,
                    color: element.color || 'rgba(255,255,255,.9)',
                    //fontWeight: 800,
                }}
            >
                {element.content}
            </div>
        );
    });
});

// Add BodyTick component

const BodyTick = memo(({ body, centerX, centerY, radius, size }) => {
    const angle = (30-body.degree) * (Math.PI / 180);
    const innerRadius = radius;
    const outerRadius = radius + size;
    
    const x1 = centerX + Math.cos(angle) * innerRadius;
    const y1 = centerY + Math.sin(angle) * innerRadius;
    const x2 = centerX + Math.cos(angle) * outerRadius;
    const y2 = centerY + Math.sin(angle) * outerRadius;
    
    return (
        <line
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            stroke={body.color || 'white'}
            strokeWidth="2"
            opacity="0.8"
        />
    );
});

const ZodiacWheel = memo(({ rotation = 0, size = 600, bodies = [], ascDeg, cusps, aspects, synastry=false, selectedAspect, setSelectedAspect, aspectSearch, asteroids = [], processedAspects, hideUI }) => {
    const viewport = useViewport();


    const {
        viewState,
        isChartVisible,
        setBodyVisibility,
        toggleBodyVisibility,
      } = useBodyViewSettings();

    // Calculate the scaled size based on viewport
    const getScaledSize = () => {
        const maxWidth = viewport.width - 0;
        const maxHeight = viewport.height - 0;
        const maximumSize = Math.min(maxWidth, maxHeight);
        return size > maximumSize ? maximumSize : size
    };
    
    const scaledSize = getScaledSize();
    
    const scaleFactor = 1//scaledSize / size; // Use this to maintain proportions
    
    const filterBodiesByViewSettings = unfilteredBodies => {
        return unfilteredBodies.filter(body => {
            const visible = isChartVisible(body.name)
            return visible !== false
        })
    }

    const bodies2 = synastry && synastry.bodies;
    const angleBodies2 = synastry && synastry.angleBodies;
    const synastryAspects = synastry && synastry.synastryAspects;
    const synastryCusps = synastry && synastry.cusps;

    const sizeFactor = synastry ? 60*scaleFactor : 0;
    
    // Use scaledSize and scaleFactor in all calculations
    const { radius, centerX, centerY, symbolRadius, centerX_s, centerY_s } = useMemo(() => 
        calculateRadii(scaledSize, sizeFactor*scaleFactor, scaleFactor ), [scaledSize, sizeFactor, scaleFactor]);
    
    const totalRotation = useMemo(() => ((ascDeg ? ascDeg : 0)+150) , [ascDeg]);
    const aspectRadius = useMemo(() => radius - (60 * scaleFactor), [radius, scaleFactor]);

    // Scale radii arrays
    const radii = useMemo(() => {
        const baseRadius = (radius - sizeFactor  * .65) * 0.7;
        const spacing = (20 - sizeFactor/10) * scaleFactor;
        return [
            baseRadius - spacing * .3 - 4 * scaleFactor,
            baseRadius + spacing * 0.3 - 4 * scaleFactor,
            baseRadius + spacing * 0.9 - 4 * scaleFactor,
            baseRadius + spacing * 1.5 - 4 * scaleFactor,
        ];
    }, [radius, sizeFactor, scaleFactor]);
    
    const radii2 = useMemo(() => {
        const baseRadius = (radius - sizeFactor * scaleFactor * .85 + 12 * scaleFactor) * 1.05;
        const spacing = (20 - sizeFactor/10) * scaleFactor;
        return [
            baseRadius + spacing * 0.3,
            baseRadius + spacing * 0.9,
            baseRadius + spacing * 1.5,
            baseRadius + spacing * 2.1,
        ];
    }, [radius, sizeFactor, scaleFactor]);


    
    
    const optimizedBodies = useMemo(() => optimizePositions(filterBodiesByViewSettings([...bodies, ...asteroids]), 6), [bodies, asteroids, viewState]);
    //const optimizedBodies_second = useMemo(() => optimizePositions(optimizedBodies_first), [optimizedBodies_first], 6);
    //const optimizedBodies = optimizedBodies_first
    const newBodies2 = bodies2 && filterBodiesByViewSettings([...bodies2, ...angleBodies2]);
    const optimizedBodies2 = bodies2 ? optimizePositions(newBodies2) : [];

    

    
    const renderAspects = (synastryAspects && synastryAspects.length > 0) ? synastryAspects : aspects;

    if (hideUI) return null;
    return (
        <div 
            className="relative mx-auto" // Added mx-auto for horizontal centering
            style={{ 
                width: (scaledSize + sizeFactor * 2 * scaleFactor) ,
                height: (scaledSize + sizeFactor * 2 * scaleFactor) ,
                // padding: scaleFactor,
                // margin: -scaleFactor,
                
                transform: `rotate(${totalRotation}deg)`,
                transition: '.5s transform',
                transformOrigin: 'center',
                
                pointerEvents: 'none',
                maxWidth: '100vw', // Prevent horizontal overflow
                maxHeight: '100vw' // Prevent vertical overflow
            }}
        >
            <svg 
                viewBox={`${-0} ${-0} ${scaledSize + sizeFactor * 2 * scaleFactor} ${scaledSize + sizeFactor * 2 * scaleFactor}`}
                // style={{margin: -siz}}
                className="absolute top-0 left-0 w-full h-full"
            >
                {synastry && <circle
                    cx={centerX}
                    cy={centerY}
                    r={radius+sizeFactor}
                    fill="rgba(0,0,0,.3)"
                    stroke="rgba(255,255,255,.2)"
                    strokeWidth="1"
                />}
                <circle
                    cx={centerX}
                    cy={centerY}
                    r={radius - 1 }
                    fill={synastry?"none":"rgba(0,0,0,.3)"}
                    stroke="rgba(255,255,255,.2)"
                    strokeWidth="20"
                />
                <circle
                    cx={centerX}
                    cy={centerY}
                    r={radius - 12}
                    fill="none"
                    stroke="rgba(255,255,255,.5)"
                    strokeWidth="1"
                />

                <circle
                    cx={centerX}
                    cy={centerY}
                    r={radius - 60}
                    fill="none"
                    stroke="rgba(255,255,255,.5)"
                    strokeWidth="1"
                    id="circle3"
                />
                <g>
                    {renderAspects?.filter(aspect => 
                        ASPECT_TYPES[aspect.aspectKey]
                    ).map((aspect, index) => (
                        <AspectLine
                        key={`${aspect.point1Key}-${aspect.point2Key}-${index}`}
                        aspect={aspect}
                        bodies={optimizedBodies}
                        selectedAspect={selectedAspect}
                        centerX={centerX}
                        centerY={centerY}
                        radius={aspectRadius}
                        bodies2={optimizedBodies2}
                        isSelected={selectedAspect ? isAspectInSearch(aspect, [[selectedAspect]]) : false
                            // [aspect].find(aspect => 
                            // aspect.point1Label === selectedAspect?.point1Label && 
                            // aspect.point2Label === selectedAspect?.point2Label &&
                            // aspect.point1Source === selectedAspect?.point1Source &&
                            // aspect.point2Source === selectedAspect?.point2Source)
                        }
                        isInSearch={isAspectInSearch(aspect, aspectSearch) }
                        clickHandler={
                            aspect => setSelectedAspect(aspect)
                        }
                        />
                    ))}
                </g>
                {signs && signs.map((_, index) => (
                    <ZodiacLine
                        key={index}
                        centerX={centerX}
                        centerY={centerY}
                        radius={radius}
                        index={index}
                    />
                ))}
                {cusps && cusps.map((cusp, index) => {
                    
                    // return <ZodiacCusp
                    //     key={`chart-cusp-${index}`}
                    //     centerX={centerX}
                    //     centerY={centerY}
                    //     radius={radius}
                    //     index={index}
                    //     degree={30-cusp}
                    // />
                    return <SynastryCusp
                        key={`chart-cusp-${index}`}
                        centerX={centerX}
                        centerY={centerY}
                        outerRadius={radius-12}
                        radius={radius-60}
                        degree={30-cusp}
                        index={index}
                    />
                })}
                {synastryCusps && synastryCusps.map((cusp, index) => (
                    <SynastryCusp
                        key={`synastry-cusp-${index}`}
                        centerX={centerX}
                        centerY={centerY}
                        outerRadius={radius + sizeFactor}
                        radius={radius+11}
                        degree={30-cusp}
                        index={index}
                    />
                ))}
                {bodies.map((body) => (
                    <BodyTick
                        key={`tick-${body.name}`}
                        body={body}
                        centerX={centerX}
                        centerY={centerY}
                        radius={radius - 60}
                        size={-4}
                        color={body.color}
                    />
                ))}
                {newBodies2 && newBodies2.map((body) => (
                    <BodyTick
                        key={`tick2-${body.name}`}
                        body={body}
                        centerX={centerX}
                        centerY={centerY}
                        radius={radius - 60}
                        size={8}
                        color={body.color}
                    />
            ))}
            </svg>

            {signs.map((sign, index) => (
                <ZodiacSymbol
                    key={sign.name}
                    sign={sign}
                    index={index}
                    centerX={centerX_s}
                    centerY={centerY_s}
                    symbolRadius={symbolRadius+3*scaledSize/size}
                    size={size}
                />
            ))}

            {optimizedBodies2 && optimizedBodies2.map((body) => (
                <CelestialBody2
                    key={`outer-${body.name}-${body.bodyName}-${body.degree}`}
                    body={body}
                    position={getSignPosition(body.degree)}
                    displayAngle={((30-body.displayDegree) * (Math.PI / 180))}
                    radii={radii2}
                    centerX={centerX_s}
                    centerY={centerY_s}
                    size={size}
                    totalRotation={totalRotation}
                />
            ))}
            {optimizedBodies.map((body) => (
                <CelestialBody
                    key={`inner-${body.name}-${body.bodyName}-${body.degree}`}
                    body={body}
                    position={getSignPosition(body.degree)}
                    displayAngle={((30-body.displayDegree) * (Math.PI / 180))}
                    radii={radii}
                    centerX={centerX_s}
                    centerY={centerY_s}
                    size={size}
                    totalRotation={totalRotation}
                />
            ))}
        </div>
    );
});

export default memo(ZodiacWheel);