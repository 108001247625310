//src/scripts/chart.js
import * as Astronomy from 'astronomy-engine'
//import {Person, ChartFactory} from 'astrochart'
import { getSignPosition } from '../data/zodiacConstants';
import { Horoscope, Origin } from 'circular-natal-horoscope-js';
import { unixToDate } from './helpers';


export const HOROSCOPE_OPTIONS = {houseSystem: "placidus",
    zodiac: "tropical",
    aspectPoints: ['bodies', 'points', 'angles'],
    aspectWithPoints: ['bodies', 'points', 'angles'],
    aspectTypes: ["major", "minor"],
    language: 'en'};


export const bodyList = ['Sun', 'Moon', 'Mercury', 'Venus', 'Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune', 'Pluto', 
    // 'Chiron'
];


export const bodyReference = {
    "Sun": {
      index: '0',
      color: '#ffff00',
      colorFunc: a => `rgba(235,200,0,${a})`,
      symbol: '☉︎',
    },
    "Moon": {
      index: '1', 
      color: '#999999',
      colorFunc: a => `rgba(153,153,153,${a})`,
      symbol: '☾',
    },
    "Mercury": {
      index: '2', 
      color: '#00ff00',
      colorFunc: a => `rgba(0,255,0,${a})`,
        symbol: '☿',
    },
    "Venus": {
      index: '3', 
      color: '#ff9900',
      colorFunc: a => `rgba(255,153,0,${a})`,
      symbol: '♀',
    },
    "Mars": {
      index: '4', 
      color: '#ff0000',
      colorFunc: a => `rgba(255,0,0,${a})`,
      symbol: '♂',
    },
    "Jupiter": {
      index: '5', 
      color: '#ff00ff',
      colorFunc: a => `rgba(255,0,255,${a})`,
      symbol: '♃',
    },
    "Saturn": {
      index: '6', 
      color: '#996633',
      colorFunc: a => `rgba(150,132,81,${a})`,
      symbol: '♄',
    },
    "Uranus": {
      index: '7', 
      color: '#00aaaa',
      colorFunc: a => `rgba(0,185,185,${a})`,
      symbol: '♅',
    },
    "Neptune": {
      index: '8', 
      color: '#0000ff',
      colorFunc: a => `rgba(0,0,255,${a})`,
      symbol: '♆',
    },
    "Pluto": {
      index: '9', 
      color: '#666666',
      colorFunc: a => `rgba(102,102,102,${a})`,
      symbol: '♇',
    },
    "Chiron": {
        index: '10',
        color: 'rgba(153,51,51,1)',
        colorFunc: a => `rgba(153,51,51,${a})`,
        symbol: "κ",
    },
    "Lilith": {
        index: '11',
        color: 'rgba(162,0,223,1)',
        colorFunc: a => `rgba(162,0,223,${a})`,
        symbol: "⚸",
    },
    "NorthNode": {
        index: '12',
        color: 'rgba(200,200,200,1)',
        colorFunc: a => `rgba(200,200,200,${a})`,
        symbol: "☊",
        axis: true,
        axisLower: false,
        noSetting: true,
    },
    "SouthNode": {
        index: '13',
        color: 'rgba(200,200,200,1)',
        colorFunc: a => `rgba(200,200,200,${a})`,
        symbol: "☋",
        axis: true,
        axisLower: true,
        noSetting: true,
    },
    "North Node": {
        index: '12',
        color: 'rgba(200,200,200,1)',
        colorFunc: a => `rgba(200,200,200,${a})`,
        symbol: "☊"
    },
    "South Node": {
        index: '13',
        color: 'rgba(200,200,200,1)',
        colorFunc: a => `rgba(200,200,200,${a})`,
        symbol: "☋"
    },
    "Ascendant": {
        index: '13',
        color: 'rgba(255,255,255,1)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "As",
        axis: true,
        axisLower: false,
    },
    "Descendant": {
        index: '13',
        color: 'rgba(255,255,255,1)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "Ds",
        axis: true,
        axisLower: true,
    },
    "Midheaven": {
        index: '13',
        color: 'rgba(255,255,255,1)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "Mc",
        axis: true,
        axisLower: false,
    },
    "Imum Coeli": {
        index: '13',
        color: 'rgba(255,255,255,1)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "Ic",
        axis: true,
        axisLower: true,
    },
    "Vertex": {
        index: '13.2',
        color: 'rgba(255,255,255,.5)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "Vx",
        axis: true,
        axisLower: false,
        aspectConjunctOnly: true,
    },
    "Antivertex": {
        index: '13.3',
        color: 'rgba(255,255,255,.5)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "Ax",
        axis: true,
        axisLower: true,
    },
    "Ceres": {
        index: '14',
        color: 'rgba(255,50,180,.8)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "⚳",
        asteroid: true,
    },
    "Vesta": {
        index: '14',
        color: 'rgba(255,50,180,.8)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "⚶",
        asteroid: true,
    },
    "Juno": {
        index: '14',
        color: 'rgba(255,50,180,.8)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "⚵",
        asteroid: true,
    },
    "Pallas": {
        index: '14',
        color: 'rgba(255,50,180,.8)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "⚴",
        asteroid: true,
    },
    "PartOfFortune": {
        index: '14',
        color: 'rgba(255,255,255,.5)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "⨂",//"✕",
        circle: false,
        part: true,
    },
    "PartOfSpirit": {
        index: '14',
        color: 'rgba(255,255,255,.5)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "Ⓘ",//"Φ",
        part: true,
    },
    "PartOfVictory": {
        index: '14',
        color: 'rgba(255,255,255,.5)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "♃",
        circle: true,
        part: true,
    },
    "PartOfNemesis": {
        index: '14',
        color: 'rgba(255,255,255,.5)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "♄",
        circle: true,
        part: true,
    },
    "PartOfEros": {
        index: '14',
        color: 'rgba(255,255,255,.5)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "♀",
        circle: true,
        part: true,
    },
    "PartOfNecessity": {
        index: '14',
        color: 'rgba(255,255,255,.5)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "☿",
        circle: true,
        part: true,
    },
    "PartOfCourage": {
        index: '14',
        color: 'rgba(255,255,255,.5)',
        colorFunc: a => `rgba(255,255,255,${a})`,
        symbol: "♂",
        circle: true,
        part: true,
    },
}
// Cache handler for astronomical calculations
class AstroCache {
    constructor() {
      this.horizonPoints = new Map();
      this.bodyPositions = new Map();
      this.timeParameters = new Map();
    }
  
    getKey(bodyName, degree, timestamp) {
      return `${bodyName}-${degree}-${timestamp}`;
    }
  
    getTimeKey(timestamp) {
      return `time-${timestamp}`;
    }
  
    getHorizonPoints(bodyName, degree, timestamp) {
      return this.horizonPoints.get(this.getKey(bodyName, degree, timestamp));
    }
  
    setHorizonPoints(bodyName, degree, timestamp, points) {
      this.horizonPoints.set(this.getKey(bodyName, degree, timestamp), points);
    }
  
    getTimeParameters(timestamp) {
      return this.timeParameters.get(this.getTimeKey(timestamp));
    }
  
    setTimeParameters(timestamp, params) {
      this.timeParameters.set(this.getTimeKey(timestamp), params);
    }
  
    clear() {
      this.horizonPoints.clear();
      this.bodyPositions.clear();
      this.timeParameters.clear();
    }
}   
  
const astroCache = new AstroCache();
  
  // Modified calculateChart function with caching
export const calculateChart = (latitude, longitude, unixTimestamp, colorParam=1) => {
    // console.log("CALCULATE CHART")
      if (!latitude || !longitude || !unixTimestamp) return [];
  
      const timeKey = `${latitude}-${longitude}-${unixTimestamp}`;
      const cachedChart = astroCache.bodyPositions.get(timeKey);
      if (cachedChart) {
          return cachedChart.map(body => ({
              ...body,
              color: bodyReference[body.name].colorFunc(colorParam)
          }));
      }
  
      const date = new Date(unixTimestamp);
      const observer = new Astronomy.Observer(latitude, longitude, 0);
      const astroTime = Astronomy.MakeTime(date);
      const bodies = bodyList.map(body => {
          const geoVector = Astronomy.GeoVector(body, date, true);
          const ecliptic = Astronomy.Ecliptic(geoVector);
          
          return {
              name: body,
              color: bodyReference[body].colorFunc(colorParam),
              symbol: bodyReference[body].symbol,
              index: bodyReference[body].index,
              degree: ecliptic.elon
          };
      });
  
      astroCache.bodyPositions.set(timeKey, bodies);
    //   console.log(bodies)
    return bodies;
};

export const calculateAngles = (horoscope) => {
    const ASCDeg = horoscope._ascendant.ChartPosition.Ecliptic.ArcDegrees;
    const ASCang = ASCDeg.degrees + ASCDeg.minutes/60;
    const MCDeg = horoscope._midheaven.ChartPosition.Ecliptic.ArcDegrees;
    const MCang = MCDeg.degrees + MCDeg.minutes/60;
  
    return [
      { bodyName: 'As', name: "Ascendant" , degree: ASCang, symbol: 'As', color: '#fff', sign: getSignPosition(ASCang) },
      { bodyName: 'Ds', name: "Descendant" , degree: (ASCang + 180) % 360, symbol: 'Ds', color: '#fff', sign: getSignPosition(ASCang+180) },
      { bodyName: 'MC', name: "Midheaven" , degree: MCang, symbol: 'Mc', color: '#fff', sign: getSignPosition(MCang) },
      { bodyName: 'IC', name: "Imum Coeli" ,degree: (MCang + 180) % 360, symbol: 'Ic', color: '#fff', sign: getSignPosition(MCang+180) },
    ];
  };
  
export const calculateNonPlanets = (horoscope) => {
    const chiron = horoscope._celestialBodies.chiron.ChartPosition.Ecliptic.ArcDegrees;
    const chironAng = chiron.degrees + chiron.minutes/60

    const lilith = horoscope._celestialPoints.lilith.ChartPosition.Ecliptic.ArcDegrees;
    const lilithAng = lilith.degrees + lilith.minutes/60

    const northnode = horoscope._celestialPoints.northnode.ChartPosition.Ecliptic.ArcDegrees;
    const northnodeAng = northnode.degrees + northnode.minutes/60

    const southnode = horoscope._celestialPoints.southnode.ChartPosition.Ecliptic.ArcDegrees;
    const southnodeAng = southnode.degrees + southnode.minutes/60

    return [
      { bodyName: 'Lilith', name: "Lilith" , degree: lilithAng, sign: getSignPosition(lilithAng), symbol: bodyReference.Lilith.symbol, color: bodyReference.Lilith.color },
      { bodyName: 'Chiron', name: "Chiron" , degree: chironAng, sign: getSignPosition(chironAng),symbol: bodyReference.Chiron.symbol, color: bodyReference.Chiron.color },
      { bodyName: 'North Node',name: "North Node" ,  degree: northnodeAng, sign: getSignPosition(northnodeAng),symbol: bodyReference.NorthNode.symbol, color: bodyReference.NorthNode.color },
      { bodyName: 'South Node',name: "South Node" ,  degree: southnodeAng, sign: getSignPosition(southnodeAng),symbol: bodyReference.SouthNode.symbol, color: bodyReference.SouthNode.color },
    ];
}

export const calculateCusps = (horoscope) => {
    const cusps = horoscope._houses.map(house => {
        const degs = house.ChartPosition.StartPosition.Ecliptic.ArcDegrees
        const deg = degs.degrees + degs.minutes/60;
        return deg
    })
    return cusps
}
export const calculateAspects= (horoscope) => {
    const aspects = horoscope._aspects.all
    return aspects;
}

export const filterPaths = (paths, options) => {
    let newPaths = [...paths]
    
    Object.keys(options).forEach(type => {
        if (!options[type]) newPaths = newPaths.filter(path => {
            return path.type.toUpperCase() !== type.toUpperCase()
        })
    })
    // console.log(newPaths)
    if (!options.second) {
        newPaths = newPaths.filter(path => path.marker.body.synastry === 1)
        newPaths = newPaths.filter(path => path.synastryPath !== true)
    } else if (!options.synastry) {
      newPaths = newPaths.filter(path => path.synastryPath !== true)
    }
    // console.log(newPaths)
    return newPaths
}



export const createOriginProps = (unixTimestamp, timezone, latitude, longitude) => {
    return {
        year: new Date(unixTimestamp).getFullYear(),
        month: new Date(unixTimestamp).getMonth(),
        date: new Date(unixTimestamp).getDate(),
        hour: parseInt(unixToDate(unixTimestamp, timezone).timeString.split(':')[0]),
        minute: parseInt(unixToDate(unixTimestamp, timezone).timeString.split(':')[1]),
        latitude,
        longitude,
    }
}

export const createHoroscope = (timestamp, time, latitude, longitude) => {
    const origin = new Origin({
      year: new Date(timestamp).getFullYear(),
      month: new Date(timestamp).getMonth(),
      date: new Date(timestamp).getDate(),
      hour: parseInt(time.split(':')[0]),
      minute: parseInt(time.split(':')[1]),
      latitude,
      longitude
    });
  
    return new Horoscope({
      origin, 
      
    });
};



  function pointsWithinEpsilon(array1, array2, epsilon = 1) {
    const isWithinLatBounds = (lat) => lat >= -60 && lat <= 60;

    let interpoint = false;
    let intergap = 100;
    for (let i = 0; i < array1.length; i++) {
      const [lat1, lng1] = array1[i];
        if (isWithinLatBounds(lat1))
            for (let j = 0; j < array2.length; j++) {
                const [lat2, lng2] = array2[j];
                
                if (isWithinLatBounds(lat2) && Math.abs(lat1 - lat2) <= epsilon && Math.abs(lng1 - lng2) <= epsilon) {
                    let gap = Math.abs(lat1 - lat2) + Math.abs(lng1 - lng2);
                    if (gap < intergap) {
                        interpoint = [lat1, lng1];
                        intergap = gap;
                    }
                }
            }
        }
    
    return interpoint;
  }

  export const calculateVertex = (origin, horoscope) => {
    const latitude = origin.latitude;
    const mcDegree = origin.localSiderealTime;
    const obliquity = 23.4367;
  
    const toRadians = deg => deg * Math.PI / 180;
    const toDegrees = rad => rad * 180 / Math.PI;
  
    const ramc = toRadians(mcDegree);
    const e = toRadians(obliquity);
    const L = toRadians(latitude);
    
  
    // VX = atan (cos RAMC ÷ ((sin e * cot L) - (cos e * sin RAMC)))
    const cotL = 1 / Math.tan(L);
    const numerator = Math.cos(ramc);
    const denominator = (Math.sin(e) * cotL) - (Math.cos(e) * Math.sin(ramc));
    
    let vertexDegree = toDegrees(Math.atan(numerator / denominator));
  
    // Quadrant corrections
    if (denominator < 0) {
      vertexDegree += 180;
    }
    vertexDegree = ((vertexDegree % 360) + 180) % 360;
  
    // Normalize to 0-360°
    
    return [{
      name: "Vertex",
      bodyName: "Vertex",
      symbol: "Vx",
      sign: getSignPosition(vertexDegree),
      color: "rgba(255,255,255,.5)",
      degree: vertexDegree,
      type: "angle",
      strokeWidth: 1
    },
    {
        name: "Antivertex",
        bodyName: "Antivertex",
        symbol: "Ax",
        sign: getSignPosition((vertexDegree + 180) % 360),
        color: "rgba(255,255,255,.5)",
        degree: (vertexDegree + 180) % 360,
        type: "angle",
        strokeWidth: 1
      }]
  };


const calculateParans = (accumulator, chartIndex) => {
    const mainLines = accumulator.filter(line => line.name.includes('-'));
    // Find all intersections between main lines
    const paranIntersections = [];
    mainLines.forEach((line1, idx1) => {
        mainLines.forEach((line2, idx2) => {
            if (idx1 >= idx2) return; // Avoid duplicate checks and self-comparisons
            const inters = pointsWithinEpsilon(line1.points, line2.points, .5)
            // calculateIntersections(line1, line2)
            if(inters !== false) {
                paranIntersections.push({   
                    coords: inters, 
                    line1: line1.name,
                    line2: line2.name,
                    marker: {
                        lat: inters[0],
                        lng: inters[1],
                        alt: 0,
                        lineLabel1: line1.name.split('-')[1].trim(),
                        size: 28,
                        body: line1.marker.body,
                        body1: line1.marker.body,
                        body2: line2.marker.body,
                        lineLabel2: line2.name.split('-')[1].trim(),
                        type: 'PARANS',
                        index: chartIndex,
                    },
                    name: line1.name + ' & ' + line2.name,
                    points: Array.from({ length: 181 }, (_, i) => i).map(i => [inters[0], i*2-180]),
                    length: 0.01,
                    gap: 0.002,
                    color: 'rgba(255,255,255,.1)',
                    strokeWidth: 1,
                    type: 'PARANS',
                    index: chartIndex,
                });
            }
        });
    });
    const newAccum = [...accumulator, ...paranIntersections]

    return newAccum;
};

export const getCoordMidpoint = (lat1, lng1, lat2, lng2) => {
  // Convert to radians
  const toRad = deg => deg * Math.PI / 180;
  const toDeg = rad => rad * 180 / Math.PI;
  
  const φ1 = toRad(lat1);
  const λ1 = toRad(lng1);
  const φ2 = toRad(lat2);
  const λ2 = toRad(lng2);

  // Convert to Cartesian coordinates
  const x1 = Math.cos(φ1) * Math.cos(λ1);
  const y1 = Math.cos(φ1) * Math.sin(λ1);
  const z1 = Math.sin(φ1);

  const x2 = Math.cos(φ2) * Math.cos(λ2);
  const y2 = Math.cos(φ2) * Math.sin(λ2);
  const z2 = Math.sin(φ2);

  // Find midpoint vector
  const x = x1 + x2;
  const y = y1 + y2;
  const z = z1 + z2;

  // Convert back to spherical coordinates
  const λm = Math.atan2(y, x);
  const hyp = Math.sqrt(x * x + y * y);
  const φm = Math.atan2(z, hyp);

  // Convert to degrees
  return {
    latitude: toDeg(φm),
    longitude: toDeg(λm)
  };
};
