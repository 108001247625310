import Globe from 'react-globe.gl';
import { renderToStaticMarkup } from "react-dom/server"
import { useState, useEffect, memo, useMemo, useRef, useCallback } from 'react';

import { filterPaths } from '../scripts/chart';
import { chunkArray, labelsProps, processMarkers, targetGenerator } from './globeComponents';
import { workerContent as pathsWorkerContent } from '../workers/pathsWorker'
import { workerContent as synParansWorkerContent } from '../workers/synastryParansWorker'

import { useRelocation } from '../contexts/relocationPathsContext';
import { useBodyViewSettings } from '../contexts/bodyViewContext';
import { usePaths } from '../contexts/pathsContext';
import { useViewData } from '../contexts/viewContext';

import { disposeWebGLResources, clearMarkers } from '../utils/webglResources';

import { useDualBirthData } from '../contexts/birthContext';
import { getCities } from '../api/trine-backend.api';
import populated_places from '../data/populated_places.json'
import * as spaceMono from '../data/SpaceMono_Regular.json';
import notoSans from '../data/NotoSans_Regular.json';
import overpass from '../data/OverpassMonoLight_Regular.json';

const CHUNK_SIZE = 9;

const GlobeMap = memo(({ onGlobeClick, stableBodies, relocation, setRelocation,}) => {
const globeEl = useRef();
const [pathsReady, setPathsReady] = useState(false);
const [loading, setLoading] = useState(false);
const [progress, setProgress] = useState(0);

const workerRef = useRef(null);
const workerSynastryParansRef = useRef(null);
const requestInProgressRef = useRef(false);
const pathsRef = useRef([]);
const expectedChunksRef = useRef(0);
const receivedChunksRef = useRef(0);
const relocationRef = useRef(relocation);
const markerPool = useRef(new Set());
const prevMarkersRef = useRef(new Set());

const debouncedZoomRef = useRef(null);

const { isLineVisible } = useBodyViewSettings();
const { getOptions } = useViewData();
const options = getOptions();
const { allPaths, addPaths, clearPaths, synastryParans, setSynastryParans } = usePaths();
const { relocPaths, updateRelocPaths, clearRelocPaths } = useRelocation();

const [worldCitiesResponse, setWorldCitiesResponse] = useState([]);
const [altitude, setAltitude] = useState(1);

const {
    birth1, birth2, birthDataComplete1, birthDataComplete2,
} = useDualBirthData();

const unixTimestamps = useMemo(() => [birth1.unixTimestamp, birth2.unixTimestamp], [birth1.unixTimestamp, birth2.unixTimestamp]);

const initWorker = (content) => {
    try {
        const blob = new Blob([content], { type: 'text/javascript' });
        const worker = new Worker(URL.createObjectURL(blob));
        return worker;
    } catch (error) {
        console.error('Error creating worker:', error);
    }
}

useEffect(() => {
    relocationRef.current = relocation;
}, [relocation]);

const updatePaths = () => {
    if (receivedChunksRef.current === expectedChunksRef.current) {
        addPaths(pathsRef.current);
        setPathsReady(true);
        setLoading(false);
        setProgress(0);
        if (relocationRef.current && !isNaN(relocationRef.current.lat)) {
            checkRelocatePaths(relocationRef.current);
        }
    }
};

useEffect(() => {
    try {
        const worker = initWorker(pathsWorkerContent);
        const synParansWorker = initWorker(synParansWorkerContent);
        
        worker.onmessage = (e) => {
            const { type, data, chartIndex, chunkInfo } = e.data;
            if (type === 'error') {
                console.error(`Error in worker for chart ${chartIndex}:`, e.data.error);
                setLoading(false);
                return;
            }

            if (type === 'regular') {
                pathsRef.current = [...pathsRef.current, ...data.paths, ...data.parans];
                
                receivedChunksRef.current++;
                if (chunkInfo) {
                    const chunkProgress = (chunkInfo.current / chunkInfo.total) * (100 / unixTimestamps.length);
                    setProgress(prev => Math.min(prev + chunkProgress, 100));
                }
                updatePaths();
            } else if (type === 'synastry') {
                const processedSynPaths = data.paths.map(path => ({
                    ...path,
                    type: `${path.type}`,
                    synastryPath: true,
                    marker: {...path.marker, synastryPath: true}
                }));
                pathsRef.current = [...pathsRef.current, ...processedSynPaths];
                receivedChunksRef.current++;
                updatePaths();
            }
        };

        synParansWorker.onmessage = (e) => {
            try {
                if (e.data && e.data.data) 
                    setSynastryParans(e.data.data.parans);
            } catch(e) {
                console.log("webworker error", e);
            }
        };

        workerRef.current = worker;
        workerSynastryParansRef.current = synParansWorker;

        return () => {
            if (workerRef.current) {
                workerRef.current.terminate();
                workerRef.current = null;
            }
            if (workerSynastryParansRef.current) {
                workerSynastryParansRef.current.terminate();
                workerSynastryParansRef.current = null;
            }
            clearMarkers(markerPool);
            clearMarkers(prevMarkersRef);
            disposeWebGLResources(globeEl);
            requestInProgressRef.current = false;
            pathsRef.current = [];
            receivedChunksRef.current = 0;
            expectedChunksRef.current = 0;
        };
    } catch (error) {
        console.error('Error initializing worker:', error);
    }
}, []);

const requestSynastryParans = async (bodiesCombined, timestamps) => {
    const filterOut = ['PartOf', 'Ascendant', 'Descendant', 'Midheaven', 'Imum Coeli', 'Vertex', 'Antivertex'];
    const filterBodies = (bodies) => {
        return bodies.filter(body => filterOut.map(n => !body.name.includes(n)).every(Boolean));
    };
    workerSynastryParansRef.current.postMessage({ 
        bodies1: filterBodies(bodiesCombined[0]), bodies2: filterBodies(bodiesCombined[1]), 
        timestamp1: timestamps[0], timestamp2: timestamps[1], 
        chartIndex1: 1, chartIndex2: 2, type: 'regular', chunkInfo: {current: 1, total: 1}
    });
}

const requestPaths = async (bodiesCombined, timestamps) => {
    // console.log("requestPaths called", bodiesCombined);
    setLoading(true);
    pathsRef.current = [];
    receivedChunksRef.current = 0;
    expectedChunksRef.current = 0;
    clearPaths();
    setProgress(0);
    setPathsReady(false);
    clearRelocPaths();
    
    let totalChunks = 0;
    for (let i = 0; i < timestamps.length; i++) {
        const bodies = bodiesCombined[i].map(body => ({
            ...body,
            synastry: i + 1
        }));
        if (bodies.length > 0) {
            const chunks = Math.ceil(bodies.length / CHUNK_SIZE);
            totalChunks += chunks;
        }
    }
    expectedChunksRef.current = totalChunks;

    const processChunks = async () => {
        for (let i = 0; i < timestamps.length; i++) {
            const bodies = bodiesCombined[i].map(body => ({
                ...body,
                synastry: i + 1
            }));
            if (bodies.length > 0) {
                const bodyChunks = chunkArray(bodies, CHUNK_SIZE);
                const totalChunks = bodyChunks.length;
        
                for (let j = 0; j < bodyChunks.length; j++) {
                    workerRef.current.postMessage({
                        bodies: bodyChunks[j],
                        timestamp: timestamps[i],
                        chartIndex: i + 1,
                        type: 'regular',
                        chunkInfo: {
                            current: j + 1,
                            total: totalChunks*2
                        }
                    });
                    await new Promise(resolve => setTimeout(resolve, 50));
                }
            }
        }
    };

    const processSynastry = async () => {
        if (bodiesCombined.length === 2) {
            const reversedStamps = [...timestamps].reverse();
            for (let i = 0; i < reversedStamps.length; i++) {
                const bodies = bodiesCombined[i]
                    .map(body => ({
                        ...body,
                        color: body.color.replace("1)", '.5)'),
                        name: `${body.name}-Syn`,
                        synastry: i + 1
                    }))
                    .filter(body => !body.name.toLowerCase().includes('part'));
                
                if (bodies.length > 0) {
                    const bodyChunks = chunkArray(bodies, CHUNK_SIZE);
                    const totalChunks = bodyChunks.length;
                    expectedChunksRef.current += totalChunks;
            
                    for (let j = 0; j < bodyChunks.length; j++) {
                        workerRef.current.postMessage({
                            bodies: bodyChunks[j],
                            timestamp: reversedStamps[i],
                            bodyTimestamp: reversedStamps[1-i],
                            chartIndex: i + 1,
                            type: 'synastry',
                            chunkInfo: {
                                current: totalChunks+j + 1,
                                total: totalChunks*2
                            }
                        });
                        await new Promise(resolve => setTimeout(resolve, 50));
                    }
                }
            }
        }
    };

    await Promise.all([processChunks(), processSynastry()]);
    requestInProgressRef.current = false;
};

useEffect(() => {
    if ((birthDataComplete1 && stableBodies[0].length === 0) || birthDataComplete2 && stableBodies[1].length === 0) {
        return;
    } else {
        if (unixTimestamps && !requestInProgressRef.current) {
            requestInProgressRef.current = true;
            requestPaths(stableBodies, unixTimestamps);
            if (birthDataComplete1 && birthDataComplete2 && stableBodies[0].length>0 && stableBodies[1].length>0) {
                requestSynastryParans(stableBodies, unixTimestamps);
            }
        }
    }
}, [stableBodies]);

const globeClick = (e) => {
    if (!allPaths || !allPaths.length) return;

    const {lat, lng} = e;
    setRelocation(e);
    
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 3959;
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a = 
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
            Math.sin(dLon/2) * Math.sin(dLon/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        return R * c;
    };

    const findClosestPointOnPath = (lat, lng, pathPoints) => {
        let minDist = Infinity;
        let closestPoint = null;
        
        for (let i = 0; i < pathPoints.length; i++) {
            const point = pathPoints[i];
            const dist = calculateDistance(lat, lng, point[0], point[1]);
            
            if (dist < minDist) {
                minDist = dist;
                closestPoint = { latitude: point[0], longitude: point[1] };
            }
        }
        
        return { distance: minDist, point: closestPoint };
    };

    const uniquePaths = new Map();

    
    allPaths.forEach(path => {
        const body = path.marker?.body?.name || path.name.split(' ')[0];
        const trimmedForSynBody = body.split('-Syn')[0];
        const fixBody = trimmedForSynBody === 'North Node' ? "Moon's Nodes" : trimmedForSynBody;

        if (!isLineVisible(fixBody)) return;

        const { distance, point } = findClosestPointOnPath(lat, lng, path.points);
        const threshold = path.type === 'PARANS' ? 75 : 250;
        
        if (distance <= threshold) {
            const key = `${path.name}-${path.type}`;
            const existingPath = uniquePaths.get(key);
            
            if (!existingPath || distance < existingPath.distance_mi) {
                uniquePaths.set(key, {
                    ...path,
                    closestPoint: point,
                    distance_mi: distance,
                    distance_km: distance * 1.60934,
                    relocation: {
                        lat: e.lat,
                        lng: e.lng
                    }
                });
            }
        }
    });

    if(options.second && options.synastry && synastryParans && synastryParans.length > 0) {
        const spWithDist = synastryParans.map(paran => ({...paran, 
            distance_mi: calculateDistance(paran.marker.lat, paran.marker.lng, lat, lng),
            distance_km: calculateDistance(paran.marker.lat, paran.marker.lng, lat, lng) * 1.60934,
            closestPoint: {latitide: paran.marker.lat, longitude: paran.marker.lng}
        }))
        spWithDist.filter(sp => sp.distance_mi < 250).forEach(sp => {
            uniquePaths.set(`${sp.name}-${sp.type}-sp`, {
                ...sp,
                relocation: {
                    lat: e.lat,
                    lng: e.lng
                }
            });
        })
    }

    const results = Array.from(uniquePaths.values());
    
    updateRelocPaths(results);
}

const checkRelocatePaths = (reloc) => {
    if (!isNaN(reloc.lat) && !isNaN(reloc.lng)) {
        globeEl && globeEl.current && globeEl.current.pointOfView({
            lat: reloc.lat,
            lng: reloc.lng,
            altitude: 1.5
        });
        if (allPaths && allPaths.length > 0) {
            globeClick(reloc);
        }
    }
}

useEffect(() => {
    if (allPaths.length > 0 && relocation && !isNaN(relocation.lat)) {
        checkRelocatePaths(relocation);
    }
}, [allPaths.length, relocation?.lat, relocation?.lng]);

const pointsData = useMemo(() => {
    if (allPaths && allPaths.length > 0) {
        // console.log(allPaths)
        let filteredPaths;
        if (options.relocate && relocation && !isNaN(relocation.lat)) {
            if (options.second) {
                if (options.synastry) {
                    filteredPaths = relocPaths;
                } else {
                    filteredPaths = relocPaths.filter(path => path.marker.synastryPath !== true);
                }
            } else {
                filteredPaths = relocPaths.filter(path => {
                    if (path.type === 'PARANS') {
                        return path.marker.index === 1 && path.marker.synastryPath !== true
                    } else {
                        return path.synastry === 1 && path.marker.synastryPath !== true
                    }
                })
            }
        } else {
            // console.log(synastryParans, options)
            if (options.synastry && options.second && synastryParans && synastryParans.length > 0) {
                filteredPaths = [...filterPaths(allPaths, options), ...synastryParans];
            } else {
                filteredPaths = filterPaths(allPaths, options);
            }
        }
        
        return filteredPaths.filter(path => {
            const body = path.marker?.body?.name || path.name.split(' ')[0];
            const trimmedForSynBody = body.split('-Syn')[0];
            const fixBody = trimmedForSynBody// === 'North Node' ? "Moon's Nodes" : trimmedForSynBody;
            // console.log(fixBody, isLineVisible(fixBody))
            return isLineVisible(fixBody);
        });
    } else {
        return [];
    }
}, [allPaths, options, relocation, relocPaths, isLineVisible, synastryParans]);


const points = useMemo(() => {
    // Clean up previous markers before creating new ones
    clearMarkers(prevMarkersRef);
    prevMarkersRef.current = markerPool.current;
    markerPool.current = new Set();

    if (!pointsData) {
        return null;
    }

    const {markers, markerElements} = processMarkers(pointsData);
    markerElements.forEach(el => markerPool.current.add(el));

    let relocMarker = [];
    if (relocation && options.relocate && !isNaN(relocation.lat)) {
        let div = document.createElement('DIV');
        div.innerHTML = renderToStaticMarkup(targetGenerator());
        const element = div.firstChild;
        markerPool.current.add(element);
        relocMarker = [{...relocation, latitude: relocation.lat, longitude: relocation.lng, alt: 0, element }];
    }

    return [...markers, ...relocMarker];
}, [pointsData, options.relocate, relocation]);

const ringsData = useMemo(() => (options.relocate && relocation) ? [{lat: relocation.lat, lng: relocation.lng, radius: 250.0/69.0}] : [], 
    [options.relocate, relocation.lat, relocation.lng]);

const ringsProps = useMemo(() => ({
    ringsData: ringsData,
    ringMaxRadius: (p) => p.radius,
    ringRepeatPeriod: 600,
    ringPropagationSpeed: 1.2,
}), [ringsData]);

const pathsProps = useMemo(() => ({
    pathsData: pointsData ? pointsData : [],
    pathPoints: points => (points.points),
    pathPointLat: d => d[0],
    pathPointLng: d => d[1],
    pathLabel: (p) => p.name,
    pathColor: (p) => p.color,
    pathDashLength: p => p.length,
    pathDashGap: p => p.gap,
    pathStroke: p => p.strokeWidth,
    pathDashAnimateTime: 120000,
}), [pointsData]);

const htmlData = useMemo(() => ({
    htmlElementsData: points,
    htmlLat: d => d.lat,
    htmlLng: d => d.lng,
    htmlAltitude: d => d.alt,
    htmlElement: d => d.element,
}), [points]);

const getGlobeCities = async (lat, lng, rad, pop) => {
    const response = await getCities(lat, lng, rad, pop);
    const features = response.map(city => ({
        bbox: [city.lat, city.lng, city.lat, city.lng],
        geometry: {
            type: 'Point',
            coordinates: [city.lng, city.lat]
        },
        properties: {
            latitude: city.lat,
            longitude: city.lng,
            name: city.city,
            pop_min: parseInt(city.population),
            pop_max: parseInt(city.population),
            country: city.country,
        },
        type: 'Feature',
    }));
    setWorldCitiesResponse(features);
}

const labelsPropsWithCities = useMemo(() => ({
    labelsData: [...populated_places.features, ...worldCitiesResponse],
    labelLat: d => d.properties.latitude,
    labelLng: d => d.properties.longitude,
    labelText: d => d.properties.name,
    labelSize: d => (Math.log(d.properties.pop_max)/80*(altitude+.5)),
    labelDotRadius: d => 0.2 * Math.log(d.properties.pop_max)/11/2,
    labelTypeface: overpass,
    labelColor: () => 'rgba(255, 244, 244, 0.5)',
    labelResolution: 2,
}), [worldCitiesResponse, altitude]);

useEffect(() => {
    return () => {
      if (debouncedZoomRef.current) {
        clearTimeout(debouncedZoomRef.current);
      }
    };
}, []);

const handleZoom = useCallback((e) => {
    if (debouncedZoomRef.current) {
      clearTimeout(debouncedZoomRef.current);
    }

    debouncedZoomRef.current = setTimeout(() => {
        const coords = globeEl.current && globeEl.current.pointOfView();
        if (coords && coords.lat !== undefined) {
            if (e.altitude > 1.5) getGlobeCities(coords.lat, coords.lng, 10000, 800000);
            else if (e.altitude < 0.3) getGlobeCities(coords.lat, coords.lng, 800, 50000);
            else if (e.altitude < 0.5) getGlobeCities(coords.lat, coords.lng, 1500, 100000);
            else getGlobeCities(coords.lat, coords.lng, 5000, 200000);
            setAltitude(e.altitude);
        }
    }, 500); 
}, []);

if (!stableBodies) return null;

return (
    <div style={{width: '100vw', position: 'relative'}}>
        {loading && Math.round(progress) < 100 && Math.round(progress) > 0 && (
            <div style={{
                position: 'absolute',
                top: '40vh',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1000,
                background: 'rgba(0,0,0,0.7)',
                padding: '10px 20px',
                borderRadius: '5px',
                color: 'white'
            }}>
                Calculating paths... {Math.round(progress)}%
            </div>
        )}
        <Globe
            ref={globeEl}
            width={window.innerWidth}

            height={window.innerHeight}
            globeImageUrl="earth-dark.jpg"
            showAtmosphere={true}
            {...labelsPropsWithCities}
            {...ringsProps}
            {...pathsProps}
            {...htmlData}
            onZoom={e => handleZoom(e)}
            onGlobeClick={globeClick}
        />
    </div>
);
});

export default GlobeMap;
