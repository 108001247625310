// src/components/LineViewSettings.js

import React from 'react';

import './LineViewSettings.css'; // Optional: For custom styling
import { bodyReference } from '../../scripts/chart';
import { useBodyViewSettings } from '../../contexts/bodyViewContext';
import { is } from 'ephemeris/build';


const ItemToggle = ({ symbol, isOn, settingName, onToggle }) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    border: isOn ? '1px solid rgba(255,255,255,1)' : '1px solid rgba(255,255,255,.5)',
    borderRadius: 12,
    width: 20, height: 20,
    backgroundColor: isOn ? 'transparent' : 'transparent',
    cursor: 'pointer',
    opacity: isOn ? 1 : 0.5,
    transition: 'opacity 0.2s ease'
  }} className="itemToggle" onClick={() => onToggle()}>
    <div style={{
      width: 20,
      height: 20,
      borderRadius: 4,
      
      
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.2s ease', color: isOn ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,.5)', textAlign: 'center', fontSize: 16,lineHeight: '12px'
    }}>
      {/* {isOn && <span style={{ color: 'black' }}>✓</span>} */}
      <div  className='symbolText' style={{lineHeight: '12px'}}>{symbol}</div>
    </div>
    {/* <span className="symbolText" style={{ fontSize: 16 }}>{aspectInfo.symbol}</span> */}
    
  </div>
);

export const ToggleSwitch = ({isOn, onClick, settingName}) => {
  return <label className="toggle-switch">
    <input
      type="checkbox"
      checked={isOn(settingName)}
      onClick={() => onClick(settingName)}
    />
    <span className="slider"></span>
  </label>
}
/**
 * Component to render a list of celestial bodies with toggle switches to control their visibility.
 *
 * @returns {JSX.Element} The rendered component.
 */
const LineViewSettings = () => {
  const {
    viewState,
    lineViewState,
    isBodyVisible,
    isLineVisible,
    isTransitVisible,
    isAspectVisible,
    isChartVisible,
    setBodyVisibility,
    toggleBodyVisibility,
    toggleFocusBodyVisibility
  } = useBodyViewSettings();

  /**
   * Handles the toggle switch change event.
   *
   * @param {string} bodyName - The name of the celestial body.
   */
  const handleToggle = (bodyName) => {
    toggleBodyVisibility(bodyName);
  };
  

  return (
    <div className="line-view-settings">
      <ul className="body-list">
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center',}}>
            <span style={{fontSize: 10, color: 'rgba(255,255,255,.5)', lineHeight: '12px'}}>VISIBILITY SETTINGS</span>
            <div style={{flex: 1}}></div>
            <div className='itemToggleLabel'>
              <div className='itemToggleLine' style={{fontSize: 8, color: 'white', lineHeight: '12px'}}>CHART</div>
            </div>
            <div className='itemToggleLabel'>
              <div className='itemToggleLine' style={{fontSize: 8, color: 'white', lineHeight: '12px'}}>LINE</div>
            </div>
            <div className='itemToggleLabel'>
              <div className='itemToggleLine' style={{fontSize: 8, color: 'white', lineHeight: '12px'}}>TRANSIT</div>
            </div>
            <div className='itemToggleLabel'>
              <div className='itemToggleLine' style={{fontSize: 8, color: 'white', lineHeight: '12px'}}>ASPECT</div>
            </div>
        </div>
        {Object.entries(bodyReference).map(([bodyName, bodyData]) => {
          if (bodyReference[bodyName].noSetting) return null;
          return <li key={bodyName} className="body-item">
            <span className="body-symbol symbolText" title={bodyName} style={ bodyData.circle ? { 
              border:'1px solid '+ bodyData.color, margin: 'auto', borderRadius: 10, width: 14,maxHeight: 14, marginRight: 8, textAlign: 'center', lineHeight: '14px', color: bodyData.color, fontSize: bodyData.symbol.length>1 ? 12 : 14} : {
              color: bodyData.color, fontSize: bodyData.symbol.length>1 ? 12 : 14}
            }>
              {bodyData.symbol}
            </span>
            <span className="body-name">{bodyName}</span>
            <ItemToggle isOn={isChartVisible(bodyName)}  settingName={bodyName} symbol={"⌾"} onToggle={() => toggleFocusBodyVisibility(bodyName, 'chart')} />
            <ItemToggle isOn={isLineVisible(bodyName)}  settingName={bodyName} symbol={"⦰"} onToggle={() => toggleFocusBodyVisibility(bodyName, 'line')} />
            <ItemToggle isOn={isTransitVisible(bodyName)}  settingName={bodyName} symbol={"⧗"} onToggle={() => toggleFocusBodyVisibility(bodyName, 'transit')} />
            <ItemToggle isOn={isAspectVisible(bodyName)}  settingName={bodyName} symbol={"⩜"} onToggle={() => toggleFocusBodyVisibility(bodyName, 'aspect')} />
            {/* <ToggleSwitch isOn={isBodyVisible} settingName={bodyName} onClick={handleToggle} /> */}
          </li>
        })}
      </ul>
    </div>
  );
};

export default LineViewSettings;
